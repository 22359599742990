<template>
  <div>
    <interestedparty ref="interestedparty" @update="loadData"/>
    <b-btn class="mr-2" variant="primary" @click="loadData">Refresh</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.interestedparty.loadData(0)">New</b-btn>    
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange()" @row-clicked="$refs.interestedparty.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" @updateCurrentPage="search.page = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import interestedparty from './interestedparty.vue'

export default {
  name: "interestedparties",
  components: { interestedparty },
  data() {
    return {
      isBusy: true,
      search:{
        page: 1,
        filters: {},
        sortby: 'interestedparty',
        sortdesc: false,
      },
      fields: [{
        key: 'interestedparty',
        label: "Interested party",
        sortable: true
      },{
        key: 'primaryexpectations',
        label: 'Primary expectations',
      },{
        key: 'secondaryexpectations',
        label: 'Secondary expectations',
      },{
        key: 'notsatisfiedexpectations',
        label: 'Not satisfied expectations',
      }],
      items: {
        total: 0,
        data: [],
      }
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("certificationbody/interestedparties/", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>
