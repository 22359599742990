<template>
  <div>
    <qualification ref="qualification" @update="loadData()"/>
    <document ref="document"/>
    <b-modal v-model="qualifications.showFilter" title="Filters">
      <b-form-group label="Status">
        <b-form-select v-model="qualifications.tempFilter.active" :options="addNullCombo(activeCombo)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Person">
        <b-form-select v-model="qualifications.tempFilter.users_id" :options="addNullCombo(qualifications.filtersMenu.users.filter(x => x.certificationbodies.includes(133)))" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Standard">
        <b-form-select v-model="qualifications.tempFilter.standards_id" :options="addNullCombo(qualifications.filtersMenu.standards.filter(x => x.certificationbodies.includes(133)))" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Role">
        <b-form-select v-model="qualifications.tempFilter.usersroles_id" :options="addNullCombo(qualifications.filtersMenu.usersroles)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="IAF">
        <b-form-select v-model="qualifications.tempFilter.nacesiafcodes_id" :options="addNullCombo(qualifications.filtersMenu.nacesiafcodes)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSetQualifications">Filter</b-btn>
          <b-btn variant="warning" @click="filterResetQualifications">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadQualifications">Refresh</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpenQualifications">Filter</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.qualification.loadData(0)">New</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="computedData" :fields="qualifications.fields" :busy="qualifications.isBusy" :no-local-sorting="true" :sort-by.sync="qualifications.search.sortby" :sort-desc.sync="qualifications.search.sortdesc" @sort-changed="sortingChangeQualifications" @row-clicked="$refs.qualification.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
      <template #cell(iafs)="row">
        <span  v-for="(item, index) of row.value"  :key="index">
          <b-btn size="sm" :variant="item.nacesno.length == 0 ? 'success' : 'danger'" v-b-tooltip.hover :title="item.nacesno.length == 0 ? 'Completo' : ('NO ' + item.nacesno.join(', '))" class="mr-1">{{ item.iafcode }}</b-btn>
        </span>
      </template>
      <template v-slot:cell(documents_id)="row">
        <b-btn size="sm" variant="primary" v-if="row.value > 0" @click="$refs.document.downloadData(row.value)">Download</b-btn>
      </template>
    </b-table>
    <tablebottom :busy="qualifications.isBusy" :currentpage="qualifications.search.page" :total="qualifications.items.total" @updateCurrentPage="qualifications.search.page = $event" @loadData="loadQualifications"/>
  </div>
</template>

<script>
import qualification from './qualification.vue';
import document from './document.vue';

export default {
  name: "qualifications",
  components : { qualification, document },
  data() {
    return {
      activeCombo: [{
        id: true,
        val: "Active"
      },{
        id: false,
        val: "Not active"
      }],
      qualifications: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            certificationbodies_id: 133,
            active: true,
            users_id: null,
            standards_id: null,
            usersroles_id: null,
            nacesiafcodes_id: null,
          },
          sortby: 'fullname',
          sortdesc: false,
        },
        tempFilter: {
          certificationbodies_id: 133,
          active: true,
          users_id: null,
          standards_id: null,
          usersroles_id: null,
          nacesiafcodes_id: null,
        },
        filtersMenu: {
          users:[],
          certificationbodies:[],
          standards: [],
          usersroles: [],
          naces: [],
        },
        fields: [{
          key: "fullname",
          label: "Fullname",
          sortable: true,
        },{
          key: 'standard',
          label: 'Standard',
          sortable: true,
        },{
          key: 'usersrole',
          label: 'Role',
          sortable: true,
        },{
          key: "iafs",
          label: "IAF",
        },{
          key: "approve_fullname",
          label: "Approved by",
        },{
          key: "fromdate",
          label: "From",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "todate",
          label: "To",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "documents_id",
          label: ""
        }],
        items: {
          total: 0,
          data: [],
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    computedData(){
      let tempItems = this.qualifications.items.data;
      for ( let item of tempItems ){
        item._cellVariants = {
          approve_fullname: item.signature_check === true ? 'success' : 'danger'
        }
      }
      return tempItems;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadQualifications();
    },
    loadQualifications(){
      this.qualifications.isBusy = true;
      this.openHttp("qualifications/qualifications/", this.qualifications.search ).then((result) => {
        this.qualifications.items = result;
        this.qualifications.isBusy = false;
      })
    },
    filterOpenQualifications(){
      this.openHttp("qualifications/qualifications/filters", null).then((result) => {
        this.qualifications.filtersMenu = result;
        this.qualifications.showFilter = true;
      })
    },
    filterSetQualifications(){
      this.qualifications.search.filters = this.qualifications.tempFilter;
      this.loadQualifications();
      this.qualifications.showFilter = false;
    },
    filterResetQualifications(){
      this.qualifications.tempFilter = {
        certificationbodies_id: 133,
        active: true,
        users_id: null,
        standards_id: null,
        usersroles_id: null,
        naces_id: null,
      };
      this.filterSetQualifications();
    },
    sortingChangeQualifications(ctx) {
      this.qualifications.search.sortby = ctx.sortBy;
      this.qualifications.search.sortdesc = ctx.sortDesc;
      this.loadQualifications();
    }
  }
}
</script>