<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Question" size="lg" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Area">
        <b-form-input v-model="data.question.question"/>
      </b-form-group>
      <b-form-group label="Standard">
        <multiselect v-model="standardsSupport" placeholder="" selectLabel="" deselectLabel="" :options="data.standards" :multiple="true" track-by="id" label="val" @input="data.question.standards = updateCombo(standardsSupport)"/>
      </b-form-group>
      <b-form-group label="Questions">
        <b-table no-border-collapse :sticky-header="(winHeight - 500) + 'px'" responsive :items="data.question.languages" :fields="fields.questions">
          <template v-slot:cell(question)="row">
            <b-form-input v-model="row.item.question"/>
          </template>
        </b-table>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" v-show="data.question.id > 0" @click="deleteData()">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "question",
  data() {
    return {
      show: false,
      data: null,
      standardsSupport: [],
      fields: {
        questions: [{
          key: 'lang',
          label: "Language"
        },{
          key: 'question',
          label: "Question"
        }]
      }
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("certificationbody/competencequestion/", { id: id }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.question.standards);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("certificationbody/competencequestion/save", this.data.question).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("certificationbody/competencequestion/delete", { id: this.data.question.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit("update");
    }
  },
}
</script>