<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Job" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Job" active>
            <b-row>
              <b-col lg="10">
                <b-form-group label="Job">
                  <b-form-input v-model="data.job.job"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Date">
                  <b-form-input type="date" v-model="data.job.date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Authorities">
              <b-form-textarea v-model="data.job.authorities" rows="3" max-rows="8"/>
            </b-form-group>
            <b-form-group label="Responsibilities">
              <b-form-textarea v-model="data.job.responsibilities" rows="3" max-rows="8"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.job.note" rows="3" max-rows="6"/>
            </b-form-group>
            <b-form-group label="Requirements">
              <multiselect v-model="requirementsSupport" placeholder="" selectLabel="" deselectLabel="" :options="data.requirements" :multiple="true" track-by="id" label="val" @input="data.job.requirements = updateCombo(requirementsSupport)"/>
            </b-form-group>
            <b-form-group label="Trainings">
              <multiselect v-model="trainingstypesSupport" placeholder="" selectLabel="" deselectLabel="" :options="data.trainingstypes" :multiple="true" track-by="id" label="val" @input="data.job.trainingstypes = updateCombo(trainingstypesSupport)"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Translations">
            <b-table no-border-collapse :sticky-header="(winHeight-355) + 'px'" responsive :items="data.job.languages" :fields="fields">
              <template v-slot:cell(authorities)="row">
                <b-form-textarea v-model="row.item.authorities" rows="3" max-rows="10"/>
              </template>
              <template v-slot:cell(responsibilities)="row">
                <b-form-textarea v-model="row.item.responsibilities" rows="3" max-rows="10"/>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" v-show="data.job.id > 0" @click="deleteData()">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "job",
  data() {
    return {
      show: false,
      data: null,
      requirementsSupport: [],
      trainingstypesSupport: [],
      fields: [{
          key: 'lang',
          label: 'Language'
        },{
          key: 'authorities',
          label: "Authorities"
        },{
          key: 'responsibilities',
          label: "Responsibilities"
        }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData(id){
      this.openHttp("certificationbody/job/", { id: id }).then((result) => {
        this.requirementsSupport = this.convertCombo(result.requirements, result.job.requirements);
        this.trainingstypesSupport = this.convertCombo(result.trainingstypes, result.job.trainingstypes);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("certificationbody/job/save", this.data.job).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("certificationbody/job/delete", {id: this.data.job.id}).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>