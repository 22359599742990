<template>
    <div>
        <b-modal v-if="data!== null" v-model="show" :title="'Date ' + data.user + ' ' + data.date" size="lg">
            <b-table responsive :items="data.usersstandardsphasesrolesdates" :fields="fields">
                <template v-slot:cell(usersroles_id)="row">
                    <b-button-group size="sm" class="mr-2">
                        <b-btn v-for="(item, index) in data.usersroles" :key="index" :variant="row.item.usersroles_id === item.id ? 'success' : ''" @click="row.item.usersroles_id = item.id">{{ item.val }}</b-btn>
                    </b-button-group>
                    <b-btn variant="warning" size="sm" @click="row.item.manday = 0; row.item.usersroles_id = null">Reset</b-btn>
                </template>
                <template v-slot:cell(manday)="row">
                    <b-form-input size="sm" type="number" v-model="row.item.manday" step="0.00001" min="0"/>
                </template>
            </b-table>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData">Save</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditcalendardate",
    data() {
        return {
            data: null,
            show: true,
            fields: [{
                key: "standard",
                label: "Standard",
            },{
                key: "phase",
                label: "Phase"
            },{
                key: "usersroles_id",
                label: "Role",
            },{
                key: "manday",
                label: "Manday",
            }]
        }
    },
    methods: {
        cleanDate(date){
            if ( date == null ) return null;
            var d = new Date( date );
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map( n => n < 10 ? `0${n}` : `${n}` ).join('-');
        },
        loadData(auditsId, usersId, date ){
            this.openHttp("audits/userroledate/", { audits_id: auditsId, users_id: usersId, date: date } ).then((result) => {
                this.data = result
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("audits/userroledate/save", {id: this.data.id, usersroles: this.data.usersstandardsphasesrolesdates}).then((result) => {
                if ( result !== null ){
                    this.show = false;
                    this.$emit("update");
                }
            })
        }
    }
}
</script>