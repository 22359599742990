<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Management review" @hidden="closeForm()" @close="closeForm()">
      <managementreviewdocument ref="document" @update="loadData(data.managementreview.id)"/>
      <managementreviewsignature ref="signature" @update="loadData(data.managementreview.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Cover" active>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Open date">
                  <b-form-input type="date" v-model="data.managementreview.fromdate"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Closure date">
                  <b-form-input type="date" v-model="data.managementreview.todate"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Period from">
                  <b-form-input type="date" v-model="data.managementreview.periodfrom"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Period to">
                  <b-form-input type="date" v-model="data.managementreview.periodto"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Participants">
              <multiselect v-model="usersSupport" :options="data.users" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.managementreview.users = updateCombo(usersSupport)"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.managementreview.note" rows="4"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Inputs" v-if="data.managementreview.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive :items="data.inputs" :fields="fields">
              <template v-slot:cell(question)="row">
                <b-form-group :label="row.item.question">
                  <b-form-textarea size="sm" :value="row.item.answer" rows="4" max-rows="10" @change="saveAnswer(row.item.questions_id, $event)"/>
                </b-form-group>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Outputs" v-if="data.managementreview.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive :items="data.outputs" :fields="fields">
              <template v-slot:cell(question)="row">
                <b-form-group :label="row.item.question">
                  <b-form-textarea size="sm" :value="row.item.answer" rows="4" max-rows="10" @change="saveAnswer(row.item.questions_id, $event)"/>
                </b-form-group>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-if="data.managementreview.id > 0" @click="deleteData()">Delete</b-btn>
          <b-btn variant="info" v-if="data.managementreview.id > 0" @click="$refs.document.generateData(data.managementreview.id)">Generate</b-btn>

        </div>
      </template>
    </b-modal>    
    <documentdownload ref="document"/>
  </div>
</template>

<script>
import managementreviewdocument from './managementreviewdocument.vue'
import managementreviewsignature from './managementreviewsignature.vue'

export default {
  name: "managementreview",
  components: { managementreviewdocument, managementreviewsignature },
  data() {
    return {
      show: false,
      data: null,
      usersSupport: [],
      fields: [{
        key: "question",
        label: "Element"
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("certificationbody/managementreview/", { id: id }).then((result) => {
        this.usersSupport = this.convertCombo(result.users, result.managementreview.users);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("certificationbody/managementreview/save", this.data.managementreview ).then((result) => {
        if ( result == true ) this.closeForm();
      })
    },
    saveAnswer(questions_id, answer){
      this.saveHttp("certificationbody/managementreview/answersave", { managementreviews_id: this.data.managementreview.id, questions_id: questions_id, answer: answer}).then((result) => {
        if ( result == true ) this.loadData(this.data.managementreview.id);
      })
    },
    deleteData(){
      this.deleteHttp("certificationbody/managementreview/delete", { id: this.data.managementreview.id }).then((result) => {
        if ( result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  }
}
</script>
