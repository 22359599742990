<template>
  <div>
    <b-modal v-if="data != null" v-model="show" size="xl" title="Internal audit" @hidden="closeForm()" @close="closeForm()">
      <document ref="document" @update="loadData(data.internalaudit.id)"/>
      <nonconformity ref="nonconformity" @update="loadData(data.internalaudit.id)"/>
      <signature ref="signature" @update="loadData(data.internalaudit.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Audit" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Open date">
                  <b-form-input type="date" v-model="data.internalaudit.fromdate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Closure date">
                  <b-form-input type="date" v-model="data.internalaudit.todate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Period from">
                  <b-form-input type="date" v-model="data.internalaudit.periodfrom"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Period to">
                  <b-form-input type="date" v-model="data.internalaudit.periodto"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Standards">
              <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.internalaudit.standards = updateCombo(standardsSupport)"/>
            </b-form-group>
            <b-form-group label="Audit team">
              <multiselect v-model="usersSupport" :options="data.users" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.internalaudit.users = updateCombo(usersSupport)"/>
            </b-form-group>
            <b-form-group label="Scope and sites">
              <b-form-textarea v-model="data.internalaudit.scope_site" rows="2"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.internalaudit.note" rows="4"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Criteria">
            <b-form-group label="Objectives">
              <b-form-textarea v-model="data.internalaudit.objectives" rows="3"/>
            </b-form-group>
            <b-form-group label="Risks and opportunities">
              <b-form-textarea v-model="data.internalaudit.risks_opportunities" rows="3"/>
            </b-form-group>
            <b-form-group label="Criteria">
              <b-form-textarea v-model="data.internalaudit.criteria" rows="3"/>
            </b-form-group>
            <b-form-group label="Methods">
              <b-form-textarea v-model="data.internalaudit.methods" rows="3"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Plan">
            <internalauditreservation ref="reservation" @update="loadData(data.internalaudit.id)"/>
            <b-btn variant="primary" @click="$refs.reservation.loadData(0, data.internalaudit.id)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" class="mt-2" responsive :items="data.plan" :fields="fields.plan" @row-clicked="$refs.reservation.loadData($event.id, data.internalaudit.id)"/>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Approved by">
                  <b-form-select v-model="data.internalaudit.approve_users_id" :options="addNullCombo(data.users, 'To be approved')" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Approve date">
                  <b-form-input type="date" v-model="data.internalaudit.approvedate"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Prepare checklist">
                  <b-btn variant="warning" @click="prepareData()">Prepare</b-btn>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Signatures">
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive :items="data.signatures" :fields="fields.signatures">
              <template v-slot:cell(signatures_id)="row">
                <b-btn :variant="row.item.signatures_check === true ? 'success' : 'danger'" size="sm" @click="$refs.signature.loadData(row.item.signatures_id)">Signature</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Checklist">
            <b-table no-border-collapse :sticky-header="(winHeight - 340 ) + 'px'" responsive :items="data.questions" :fields="fields.evidences">
              <template v-slot:cell(point)="row">
                <b>{{ row.item.points }}</b><br>
                <b>{{ row.item.title }}</b>
                <div v-html="row.item.description"></div>
              </template>
              <template v-slot:cell(evidences)="row">
                <b-form-textarea size="sm" :value="row.item.evidences" rows="10" max-rows="20" @change="saveEvidence(row.item.id, $event)"/>
              </template>
              <template v-slot:cell(opportunity)="row">
                <b-form-textarea size="sm" :value="row.item.opportunity" rows="10" max-rows="20" @change="saveOpportunity(row.item.id, $event)"/>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Non conformities">
            <b-btn variant="primary" size="sm" @click="$refs.nonconformity.loadData(0, data.internalaudit.id)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 340 ) + 'px'" class="mt-3" responsive :items="data.nonconformities" :fields="fields.nonconformities" @row-clicked="$refs.nonconformity.loadData($event.id, data.internalaudit.id)"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.internalaudit.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" v-if="data.internalaudit.id > 0" @click="deleteData()">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import internalauditreservation from './internalauditreservation.vue';
import document from './document.vue';
import nonconformity from '../nonconformities/nonconformity.vue';
import signature from './signature.vue';

export default {
  name: "internalauditaudit",
  components : { internalauditreservation, document, nonconformity, signature },
  data() {
    return {
      show: false,
      data: null,
      standardsSupport: [],
      usersSupport: [],
      fields: {
        signatures: [{
          key: "fullname",
          label: "Fullname"
        },{
          key: "signatures_id",
          label: ""
        }],
        plan: [{
          key: "date",
          label: "Date",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "fromtime",
          label: "From"
        },{
          key: "totime",
          label: "To"
        },{
          key: "users",
          label: "Participants"
        },{
          key: "standardspoints",
          label: "Points"
        },{
          key: "note",
          label: "Note"
        }],
        evidences: [{
          key: "point",
          label: "Point",
          thStyle: { width: "35%" }
        },{
          key: "evidences",
          label: "Evidences",
          thStyle: { width: "35%" }
        },{
          key: "opportunity",
          label: "Opportunity",
          thStyle: { width: "30%" }
        }],
        nonconformities: [{
          key: 'prog',
          label: "Progressive",
          sortable: true
        },{
          key: 'nonconformity',
          label: 'Non conformity',
        },{
          key: 'causes',
          label: 'Causes',
        },{
          key: 'treatment',
          label: 'Treatment',
        },{
          key: 'correctiveaction',
          label: 'Corrective action',
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("certificationbody/internalaudit/", { id: id }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.internalaudit.standards);
        this.usersSupport = this.convertCombo(result.users, result.internalaudit.users);
        this.data = result;
        this.show = true;
      })
    },
    prepareData(){
      this.saveHttp("certificationbody/internalaudit/prepare", { id: this.data.internalaudit.id}).then((result) => {
        if (result !== null) this.loadData(this.data.internalaudit.id);
      })
    },
    saveEvidence(questionsId, evidence){
      this.saveHttp("certificationbody/internalaudit/evidencesave", { internalaudits_id: this.data.internalaudit.id, questions_id: questionsId, evidences: evidence}).then((result) => {
        if (result !== null) this.loadData(this.data.internalaudit.id);
      })
    },
    saveOpportunity(questionsId, opportunity){
      this.saveHttp("certificationbody/internalaudit/opportunitysave", { internalaudits_id: this.data.internalaudit.id, questions_id: questionsId, opportunity: opportunity}).then((result) => {
        if (result !== null) this.loadData(this.data.internalaudit.id);
      })
    },
    saveData(){
      this.saveHttp("certificationbody/internalaudit/save", this.data.internalaudit).then((result) => {
        if ( result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("certificationbody/internalaudit/delete", { id: this.data.internalaudit.id }).then((result) => {
        if ( result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  }
}
</script>