<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Requirement" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Requirement">
        <b-form-input v-model="data.requirement.requirement"/>
      </b-form-group>
      <b-form-group label="Jobs">
        <multiselect v-model="jobsSupport" placeholder="" selectLabel="" deselectLabel="" :options="data.jobs" :multiple="true" track-by="id" label="val" @input="data.requirement.jobs = updateCombo(jobsSupport)"/>
      </b-form-group>
      <b-form-group label="Evaluation method">
        <b-form-textarea v-model="data.requirement.evaluationmethod" rows="3" max-rows="15"/>
      </b-form-group>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.requirement.note" rows="3" max-rows="15"/>
      </b-form-group>
      <b-form-group label="SQL">
        <b-form-textarea v-model="data.requirement.codesql" rows="3" max-rows="15"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" v-show="data.requirement.id > 0" @click="deleteData()">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "requirement",
  data() {
    return {
      show: false,
      data: null,
      jobsSupport: [],
      fields:[{
        key: "orderquestion",
        label: "Order",
        sortable: true,
        thStyle: { width: "10%" },
      },{
        key: "question",
        label: "Question",
        sortable: true,
        thStyle: { width: "80%" }
      },{
        key: "id",
        label: "",
        thStyle: { width: "10%" }
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("certificationbody/requirement/", { id: id }).then((result) => {
        this.jobsSupport = this.convertCombo(result.jobs, result.requirement.jobs);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("certificationbody/requirement/save", this.data.requirement).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("certificationbody/requirement/delete", { id: this.data.requirement.id }).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>