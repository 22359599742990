<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Contract" scrollable @hidden="closeForm()" @close="closeForm()">
      <contractstandard ref="contractstandard" @update="loadData(data.contract.id)"/>
      <contractsite ref="site" @update="loadData(data.contract.id)"/>
      <contractscope ref="scope" @update="loadData(data.contract.id)"/>
      <contractparameter ref="parameter" @update="loadData(data.contract.id)"/>
      <contractrelease ref="release" @update="loadData(data.contract.id)"/>
      <contractcost ref="cost" @update="loadData(data.contract.id)"/>
      <contracthistory ref="history" @update="loadData(data.contract.id)"/>
      <contractdocument ref="document" @update="loadData(data.contract.id)"/>
      <mail ref="mail" @update="loadData(data.contract.id)"/>
      <mailnew ref="mailnew" @update="loadData(data.application.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Contract" active>
            <b-row>
              <b-col lg="8">
                <b-form-group label="Company">
                  <b-form-select v-model="data.contract.companies_id" :options="data.companies" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Contract">
                  <b-form-input v-model="data.contract.contract"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Issue date">
                  <b-form-input type="date" v-model="data.contract.issuedate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Expiry date">
                  <b-form-input type="date" v-model="data.contract.expiracydate"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="State">
                  <b-form-select v-model="data.contract.contractsstatus_id" :options="data.contractsstatus.filter(x => [2,3,5].includes(x.id))" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Type">
                  <b-form-select v-model="data.contract.contractstypes_id" :options="data.contractstypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Management representative">
                  <b-form-select v-model="data.contract.workers_id" :options="data.workers" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Languages">
                  <multiselect v-model="languagesSupport" :options="data.languages" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.contract.languages = updateCombo(languagesSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Standards">
              <b-btn variant="primary" size="sm" @click="$refs.contractstandard.loadData(data.contract.id, 0)">New</b-btn>
              <b-table no-border-collapse :sticky-header="(winHeight - 205) / 3 + 'px'" class="mt-2" :items="data.standards" :fields="fields.standards" @row-clicked="$refs.contractstandard.loadData(data.contract.id, $event.id)" :tbody-tr-class="rowClassStandards"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Sites">
            <b-btn variant="primary" size="sm" @click="$refs.site.loadData(data.contract.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335)+ 'px'" class="mt-2" :items="data.sites" :fields="fields.sites" @row-clicked="$refs.site.loadData(data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab title="Scopes">
            <b-btn variant="primary" size="sm" @click="$refs.scope.loadData(data.contract.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" class="mt-2" :items="data.scopes" :fields="fields.scopes" @row-clicked="$refs.scope.loadData(data.contract.id, $event.id)">
              <template v-slot:cell(languages)="row">
                <div v-for="(item,index) of row.value" :key="index">
                <b>{{ item.language }}: </b>{{ item.scopeval }}
                </div>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Parameters">
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" :items="data.parameters" :fields="fields.parameters" @row-clicked="$refs.parameter.loadData(data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab title="Certificates">
            <b-btn variant="primary" size="sm" class="mr-2" @click="$refs.release.loadData(data.contract.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 385) + 'px'" class="mt-2" :items="data.releases" :fields="fields.releases" @row-clicked="$refs.release.loadData(data.contract.id, $event.id)">
              <template v-slot:cell(scopes)="row">
                <div v-for="(item,index) of row.value" :key="index">
                <b>{{ item.lang }}: </b>{{ item.scope }}
                </div>
              </template>
              <template v-slot:cell(sites)="row">
                <div v-for="(item,index) of row.value" :key="index">
                  <nobr>
                    <b>{{ [item.ismain === true ? 'Pr.' : null, item.isregistered === true ? 'Leg.' : null, item.isoperative === true ? 'Op.' : null].filter(x => x!= null).join(", ") }}: </b>
                    {{ item.site }}
                    <b>{{ item.phases }}</b>
                  </nobr>
                </div>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Years & Mandays" v-if="data.contract.id > 0">
            <b-btn variant="primary" size="sm" class="mr-2" @click="$refs.cost.loadData(data.contract.id, 0)">New</b-btn>
            <b-btn variant="warning" size="sm" class="mr-2" @click="calculateMandays()">Calculate Mandays</b-btn>
            <b-table no-border-collapse  :sticky-header="(winHeight - 385) + 'px'" class="mt-2" :items="data.costsyears" :fields="fields.costsyears" @row-clicked="$refs.cost.loadData(data.contract.id, $event.id)">
              <template v-slot:cell(sites)="row">
                <div v-for="(item, index) of row.item.sites" :key="index">{{ item.site }}</div>
              </template>
              <template #cell(show_details)="row">
                <b-btn size="sm" @click="row.toggleDetails">{{ row.detailsShowing ? 'Hide' : 'Show'}}</b-btn>
              </template>
              <template #row-details="row">
                <b-table responsive :fields="fields.mandaysdetails" :items="row.item.mandaysdetails">
                  <template v-slot:cell(note)="row2">
                    <div v-for="(item, index) of row2.value.split('\n')" :key="index">{{ item }}</div>
                  </template>
                </b-table>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Decisions" v-if="data.contract.contractsstatus_id > 1">
            <b-btn variant="primary" size="sm" @click="$refs.history.loadData(data.contract.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 385) + 'px'" :items="data.histories" :fields="fields.histories" class="mt-2"  @row-clicked="$refs.history.loadData(data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab title="Mails">
            <b-btn variant="primary" size="sm" class="mr-2" v-show="data.contract.id > 0" @click="mailNew()">New</b-btn>
            <b-btn variant="primary" size="sm" class="mr-2" v-show="data.contract.id > 0" @click="mailContract()">Contract</b-btn>
            <b-table :sticky-header="(winHeight - 385) + 'px'" :tbody-tr-class="rowClassMails" class="mt-2" responsive :items="data.mails" :fields="fields.mails" @row-clicked="loadMail($event.id)" :filter="fields.mailsCriteria"/>
          </b-tab>
          <b-tab title="Documents">
            <b-btn variant="info" class="mr-2" size="sm" @click="$refs.document.generateData(data.contract.id)">Generate</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 385)+ 'px'" :tbody-tr-class="rowClassDocuments" class="mt-2" :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData(data.contract.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadData(row.item.id)">Download</b-btn>
              </template>            
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" v-show="data.contract.id > 0" @click="loadData(data.contract.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.contract.id > 0" @click="deleteData()">Delete</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.contract.id > 0" @click="externalLink()">Link</b-btn>
          <b-btn v-if="data.contract.contractsstatus_id >= 2" variant="info" size="sm" class="mr-2" @click="prepareAudits()">Prepate Audits</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import contractstandard from './contractcomponents/contractstandard.vue';
import contractsite from './contractcomponents/contractsite.vue';
import contractscope from './contractcomponents/contractscope.vue';
import contractparameter from './contractcomponents/contractparameter.vue';
import contractrelease from './contractcomponents/contractrelease.vue';
import contractcost from './contractcomponents/contractcost.vue';
import contracthistory from './contractcomponents/contracthistory.vue';
import contractdocument from './contractcomponents/contractdocument.vue';

export default {
  name: "contract",
  components: { contractstandard, contractsite, contractscope, contractparameter, contractrelease, contractcost, contracthistory, contractdocument },
  data() {
    return {
      show: true,
      data: null,
      languagesSupport: [],
      companies_idSupport: [],
      fields: {
        standards: [{
          key: 'standard',
          label: "Standard",
        },{
          key: 'certificatecode',
          label: 'Certificate',
        },{
          key: 'certificatefirstissue',
          label: "1st issue",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }],
        sites: [{
          key: 'site',
          label: 'Site',
        },{
          key: 'state',
          label: 'State',
        },{
          key: 'employeesnumber',
          label: 'Wrks. totals',
        },{
          key: 'employeeseffectivenumber',
          label: 'Wrks. effective',
        },{
          key: 'employeesexternal',
          label: 'Wrks. extermal',
        },{
          key: 'employeesrepetitive',
          label: 'Wrks. repetitive',
        },{
          key: 'shifts',
          label: 'Shifts',
        },{
          key: 'naces',
          label: 'Activities',
        }],
        scopes: [{
          key: 'languages',
          label: 'Scope',
        },{
          key: 'naces',
          label: 'NACEs',
        },{
          key: "standardsexclusions",
          label: "Exclusions"
        }],
        parameters: [{
          key: 'type',
          label: 'Parameter'
        },{
          key: 'val',
          label: 'Value'
        }],
        releases: [{
          key: "standard",
          label: "Standard",
          sortable: true,
        },{
          key: "revnumber",
          label: "Rev",
          sortable: true,
        },{
          key: "riskslevelen",
          label: "Risk",
        },{
          key: "scopes",
          label: "Scope",
        },{
          key: "sites",
          label: "Sites & sampling",
        },{
          key: "issuedate",
          label: "Issue",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "validfromdate",
          label: "Validity",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "expiracydate",
          label: "Expiration",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }],
        costsyears: [{
          key: "year",
          label: "Year"
        },{
          key: 'phase',
          label: 'Phase'
        },{
          key: 'cost',
          label: 'Price (€)'
        },{
          key: 'mandays',
          label: 'Mandays',
        },{
          key: 'phases',
          label: 'Phases',
        },{
          key: 'standards',
          label: 'Standards',
        },{
          key: "plandate",
          label: "Plan",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "expiracy",
          label: "Expiration",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "status",
          label: "Status"
        },{
          key: "show_details",
          label: ""
        }],
        mandaysdetails:[{
          key: "standard",
          label: "Standard"
        },{
          key: "mandaystable",
          label: "Mandays table"
        },{
          key: "mandaysreduced",
          label: "Mandays reduced"
        },{
          key: "mandaysonsite",
          label: "Mandays on site"
        },{
          key: "note",
          label: "Note"
        }],
        histories: [{
          key: "date",
          label: "Date",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "standard",
          label: "Standard",
          sortable: true,
        },{
          key: "revnumber",
          label: "Revision",
          sortable: true,
        },{
          key: 'fullname',
          label: 'Decided by'
        },{
          key: "accreditated",
          label: "Accreditated",
          formatter: value => { return value == true ? "Yes" : "No"}
        },{
          key: "contractstandardstate",
          label: "State",
        },{
          key: "note",
          label: "Note"
        }],
        mails: [{
          key: 'folder',
          label: "Folder",
          sortable: true
        },{
          key: 'from',
          label: 'By',
        },{
          key: 'to',
          label: 'To',
        },{
          key: 'subject',
          label: "Subject",
        },{
          key: 'datetime',
          label: "Date",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }],
        mailsCriteria: null,
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    cleanDate(date){
      var d = new Date(date);
      return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
    },
    rowClassStandards(item, type){
      if (!item || type !== 'row') return "";
      return item.active === false ? "table-danger" : "";
    },
    rowClassMails(item) {
      if ( item !== null ) return item.seen === false ? "table-primary" : (item.ping === true ? "table-success" : "");
    },
    rowClassDocuments(item) {
      return item !== null && item.check === false ? "table-danger" : "";
    },
    loadData( id ){
      if ( this.data === null ) this.show = false;
      this.fields.mailsCriteria = null;
      this.openHttp("contracts/contract/", { id: id }).then((result) => {
        this.languagesSupport = this.convertCombo(result.languages, result.contract.languages);
        this.data = result;
        this.show = true;
      })
    },
    loadMail(id){
      this.$refs.mail.loadData( id );
    },
    calculateMandays(){
      this.saveHttp("contracts/mandays", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.loadData( this.data.contract.id );
      })
    },
    prepareAudits(){
      this.saveHttp("contracts/contract/auditsprepare", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.loadData( this.data.contract.id );
      })
    },
    mandaysSteps(){
      this.saveHttp("contracts/contract/mandayssteps", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.loadData( this.data.contract.id );
      })
    },
    mailNew(){
      this.openHttp("contracts/contract/mail", { id: this.data.contract.id }).then((result) => {
        this.$refs.mailnew.loadData(result);
      })
    },
    mailContract(){
      this.openHttp("contracts/contract/mailcontract", { id: this.data.contract.id }).then((result) => {
        this.$refs.mailnew.loadData(result);
      })
    },
    externalLink(){
      window.open("/#/externalcontract/" + this.data.token, '_blank');
    },
    saveData(){
      this.saveHttp("contracts/contract/save", this.data.contract).then((result) => {
        if (result !== null) this.loadData(this.data.contract.id);
      })
    },
    deleteData(){
      this.deleteHttp("contracts/contract/delete", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>