<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Scope" size="lg" @hidden="closeForm()" @close="closeForm()">
            <b-row>
                <b-col lg="12">
                    <b-btn variant="primary" size="sm" @click="data.scope.languages.push({id: null, scopeval: null})">New</b-btn>
                    <b-table no-border-collapse class="mt-2" :items="data.scope.languages" :fields="fields">
                        <template v-slot:cell(id)="row">
                        <b-form-select v-model="row.item.id" :options="data.languages.map(elem => ({ id: elem.id, val: elem.val, disabled: row.item.id > 0 ? (row.item.id === elem.id ? false : true) : ( data.scope.languages.map(x => x.id).includes(elem.id) ) }))" value-field="id" text-field="val"/>
                    </template>
                    <template v-slot:cell(scopeval)="row">
                        <b-form-textarea v-model="row.item.scopeval" rows="4"/>
                    </template>
                    <template v-slot:cell(delete)="row">
                        <b-btn v-if="row.item.id > 0" variant="danger" @click="data.scope.languages.splice(data.scope.languages.findIndex(x => x.id === row.item.id), 1)">Delete</b-btn>
                    </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="12">
                    <b-form-group label="NACE">
                        <multiselect v-model="nacesSupport" :options="data.naces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.scope.naces = updateCombo(nacesSupport)"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="data.standardsexclusions.length > 0">
                <b-col lg="12">
                    <b-form-group label="Esclusioni">
                        <b-form-checkbox-group v-model="data.scope.standardsexclusions">
                            <b-form-checkbox v-for="(item2, index2) of data.standardsexclusions" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
                    <b-btn variant="danger" v-show="data.scope.languages_id > 0" @click="deleteData()">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractscope",
    data() {
        return {
            show: false,
            data: null,
            fields: [{
                key: "id",
                label: "Language",
            },{
                key: "scopeval",
                label: "Scope",
            },{
                key: "delete",
                label: "",
            }],
            nacesSupport: [],
        }
    },
    methods: {
        loadData(contractsId, id){
            this.openHttp("contracts/scope/", { contracts_id: contractsId, id: id }).then((result) => {
                this.nacesSupport = this.convertCombo(result.naces, result.scope.naces);
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/scope/save", this.data.scope).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/scope/delete", this.data.scope).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>