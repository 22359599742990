<template>
  <div>
    <managementreviewdocument ref="document" @update="loadData()"/>
    <managementreview ref="managementreview" @update="loadData()"/>
    <b-btn class="mr-2" variant="primary" @click="loadData">Refresh</b-btn>
    <b-btn variant="success" @click="$refs.managementreview.loadData(0)">New</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.managementreview.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
      <template v-slot:cell(documents_id)="row">
        <b-btn variant="primary" size="sm" v-if="row.item.documents_id > 0" @click="$refs.document.downloadData(row.item.documents_id)">Download</b-btn>
      </template>
    </b-table>
    <tablebottom :perpage="search.perpage" @updatePerPage="search.perpage = $event" :currentpage="search.page" @updateCurrentPage="search.page = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import managementreview from './managementreview.vue'
import managementreviewdocument from './managementreviewdocument.vue';

export default {
  name: "managementreviews",
  components: { managementreview, managementreviewdocument },
  data() {
    return {
      isBusy: true,
      search:{
        page: 1,
        filters: {},
        sortby: "fromdate",
        sortdesc: false,
      },
      fields: [{
        key: 'fromdate',
        label: 'From',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'todate',
        label: 'To',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'periodfrom',
        label: 'Period from',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'periodto',
        label: 'Period to',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'users',
        label: 'Participants',
      },{
        key: 'documents_id',
        label: ''
      }],
      items: {
        total: 0,
        data: [],
      }
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){      
      this.isBusy = true;
      this.openHttp("certificationbody/managementreviews/", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>
