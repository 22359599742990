<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Training type" scrollable size="lg" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Type" active>
            <b-form-group label="Training type">
              <b-form-input v-model="data.type.trainingtype"/>
            </b-form-group>
            <b-row>
              <b-col lg="3">
                <b-form-group label="From">
                  <b-form-input v-model="data.type.fromdate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="To">
                  <b-input-group>
                    <b-form-input v-model="data.type.todate" type="date"/>
                    <b-input-group-append>
                      <b-button variant="outline-danger" @click="data.type.todate = null">Clean</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Jobs">
              <multiselect v-model="jobsSupport" :options="data.jobs" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.type.jobs = updateCombo(jobsSupport)"/>
            </b-form-group>
            <b-form-group label="Documents">
              <multiselect v-model="documentssystemrevisionsSupport" :options="data.documentssystemrevisions" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.type.documentssystemrevisions = updateCombo(documentssystemrevisionsSupport)"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.type.note" rows="2"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Questions">
            <b-btn variant="primary" size="sm" @click="newQuestion()">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 345) + 'px'" class="mt-3" responsive :items="data.type.questions" :fields="fields.questions">
              <template v-slot:cell(question)="row">
                <b-form-input v-model="row.item.question"/>
              </template>
              <template v-slot:cell(orderquestion)="row">
                <b-form-input v-model="row.item.orderquestion" size="sm" type="number"/>
              </template>
              <template v-slot:cell(yesno)="row">
                <b-button-group>
                  <b-button :variant="row.item.yesno === true ? 'success' : 'light'" @click="row.item.yesno = true">Yes</b-button>
                  <b-button :variant="row.item.yesno === false ? 'danger' : 'light'" @click="row.item.yesno = false">No</b-button>
                </b-button-group>
              </template>
              <template v-slot:cell(id)="row">
                <b-button variant="danger" size="sm" @click="data.type.questions.splice(row.index,1)">Delete</b-button>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" v-show="data.type.id > 0" @click="deleteData()">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "trainingtype",
  data() {
    return {
      show: false,
      showUpload: false,
      jobdescriptionsSupport: [],
      documentssystemrevisionsSupport: [],
      data: null,
      file: null,
      fields: {
        slides: [{
          key: "filename",
          label: "File",
        },{
          key: "id",
          label: "",
        }],
        questions: [{
          key: "orderquestion",
          label: "Order",
          sortable: true,
          thStyle: { width: "10%" }
        },{
          key: "question",
          label: "Question",
          sortable: true,
          thStyle: { width: "70%" }
        },{
          key: "yesno",
          label: "Yes/No",
          sortable: true,
          thStyle: { width: "10%" }
        },{
          key: "id",
          label: "",
          thStyle: { width: "10%" }
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData(id){
      this.openHttp("certificationbody/trainingtype/", { id: id }).then((result) => {
        this.jobsSupport = this.convertCombo(result.jobs, result.type.jobs);
        this.documentssystemrevisionsSupport = this.convertCombo(result.documentssystemrevisions, result.type.documentssystemrevisions);
        this.data = result;
        this.show = true;
      })
    },
    newQuestion(){
      this.data.type.questions.push({
        id: 0, 
        orderquestion: this.data.type.questions.length === 0 ? 1 : (Math.max(...this.data.type.questions.map(o => o.orderquestion)) + 1),
        question: null,
        yesno: null
      })
    },
    saveData(){
      this.saveHttp("certificationbody/trainingtype/save", this.data.type).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("certificationbody/trainingtype/delete", { id: this.data.type.id }).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>
