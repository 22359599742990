<template>
  <div>
    <b-modal v-if="data != null" v-model="show" size="lg" title="Internal audit reservation" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="3">
          <b-form-group label="Date">
            <b-form-input type="date" v-model="data.reservation.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="From time">
            <b-form-input type="time" size="sm" v-model="data.reservation.fromtime"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="To time">
            <b-form-input type="time" size="sm" v-model="data.reservation.totime"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Audit team">
        <b-form-checkbox-group v-model="data.reservation.users">
          <b-form-checkbox v-for="(item, index) of data.users" :key="index" :value="item.id">{{ item.val }}</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
      <b-form-group label="Standard points">
        <b-form-checkbox-group v-model="data.reservation.standardspoints">
          <div v-for="(item, index) of data.standardspoints" :key="index">
            <strong>{{ item.standard }}</strong>
            <br>
            <b-form-checkbox v-for="(item2, index2) of item.points" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
          </div>
        </b-form-checkbox-group>
      </b-form-group>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.reservation.note" rows="4"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.reservation.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "internalauditreservation",
  data() {
    return {
      show: false,
      data: null,
      usersSupport: []
    }
  },
  methods: {
    loadData(id, internalaudits_id){
      this.openHttp("certificationbody/internalauditreservation/", { id: id, internalaudits_id: internalaudits_id }).then((result) => {
        this.usersSupport = this.convertCombo(result.users, result.reservation.users);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("certificationbody/internalauditreservation/save", this.data.reservation).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("certificationbody/internalauditreservation/delete", { id: this.data.reservation.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>
