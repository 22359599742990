<template>
  <div>
    <internalaudit ref="audit" @update="loadData()"/>
    <b-btn class="mr-2" variant="primary" @click="loadData()">Refresh</b-btn>
    <b-btn variant="success" @click="$refs.audit.loadData(0)">New</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.audit.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
    </b-table>
    <tablebottom :currentpage="search.page" @updateCurrentPage="search.page = $event" :total="items.total" @loadData="loadData()"/>
  </div>
</template>

<script>
import internalaudit from './internalaudit.vue'

export default {
  name: "internalaudits",
  components: { internalaudit },
  data() {
    return {
      isBusy: true,
      search:{
        page: 1,
        filters: {
        },
        sortby: 'fromdate',
        sortdesc: false,
      },
      fields: [{
        key: 'fromdate',
        label: 'From',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'todate',
        label: 'To',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'users',
        label: 'Audit Team',
      },{
        key: 'standards',
        label: 'Standards',
      },{
        key: 'plan',
        label: 'Plan',
      },{
        key: 'report',
        label: 'Report',
      }],
      items: {
        total: 0,
        data: [],
      }
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("certificationbody/internalaudits/", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>