<template>
  <div>
    <application ref="application" @update="loadData"/>
    <b-modal v-if="showFilter" v-model="showFilter" title="Filters">
      <b-form-group label="Companies">
        <b-form-select v-model="tempFilter.companies_id" :options="addNullCombo(filtersMenu.companies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Standards">
        <b-form-select v-model="tempFilter.standards_id" :options="addNullCombo(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="IAF">
        <b-form-select v-model="tempFilter.nacesiafcodes_id" :options="addNullCombo(filtersMenu.nacesiafcodes)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filter</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Refresh</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filter</b-btn>
    <b-btn variant="success" @click="$refs.application.loadData(0)">New</b-btn>
    <b-card no-body class="mt-2">
      <b-tabs card>
        <b-tab title="Opened">
          <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" :items="contracts1.items.data" :fields="contracts1.fields" :busy="contracts1.isBusy" :no-local-sorting="true" :sort-by.sync="contracts1.search.sortby" :sort-desc.sync="contracts1.search.sortdesc" @sort-changed="sortingChange1" @row-clicked="$refs.application.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
          </b-table>
          <tablebottom :busy="contracts1.isBusy" :currentpage="contracts1.search.page" :total="contracts1.items.total" @updateCurrentPage="contracts1.search.page = $event" @loadData="loadData1"/>
        </b-tab>
        <b-tab title="Failed">
          <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" :items="contracts4.items.data" :fields="contracts4.fields" :busy="contracts4.isBusy" :no-local-sorting="true" :sort-by.sync="contracts4.search.sortby" :sort-desc.sync="contracts4.search.sortdesc" @sort-changed="sortingChange4" @row-clicked="$refs.application.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
          </b-table>
          <tablebottom :busy="contracts4.isBusy" :currentpage="contracts4.search.page" :total="contracts4.items.total" @updateCurrentPage="contracts4.search.page = $event" @loadData="loadData4"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "applications",
  data() {
    return {
      showFilter: false,
      tempFilter: {
        companies_id: null,
        standards_id: null,
        certificationbodies_id: 133,
        nacesiafcodes_id: null,
      },
      filtersMenu: {
        companies:[],
        standards:[],
      },
      contracts1: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            contractsstatus_id: 1,
            companies_id: null,
            standards_id: null,
            certificationbodies_id: 133,
            nacesiafcodes_id: null
          },
          sortby: 'expiracydate',
          sortdesc: false,
        },
        items: {
          total: 0,
          data: [],
        },
        fields: [{
          key: 'company',
          label: 'Company',
          sortable: true,
        },{
          key: 'standards',
          label: 'Standards',
          formatter: value => {
            if (value == null) return null;
            return value.join(', ');
          }
        },{
          key: 'typecertification',
          label: 'Type',
        },{
          key: 'create_date',
          label: 'Creation',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }]
      },
      contracts4: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            contractsstatus_id: 4,
            companies_id: null,
            standards_id: null,
            certificationbodies_id: 133,
            nacesiafcodes_id: null
          },
          sortby: 'expiracydate',
          sortdesc: false,
        },
        items: {
          total: 0,
          data: [],
        },
        fields: [{
          key: 'company',
          label: 'Company',
          sortable: true,
        },{
          key: 'standards',
          label: 'Standards',
          formatter: value => {
            if (value == null) return null;
            return value.join(', ');
          }
        },{
          key: 'typecertification',
          label: 'Type',
        },{
          key: 'create_date',
          label: 'Creation',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'reviewdate',
          label: 'Review',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadData1();
      this.loadData4();
    },
    loadData1(){
      this.contracts1.isBusy = true;
      this.openHttp("contracts/", this.contracts1.search).then((result) => {
        this.contracts1.isBusy = false;
        this.contracts1.items = result;
      })
    },
    sortingChange1(ctx) {
      this.contracts1.search.sortby = ctx.sortBy;
      this.contracts1.search.sortdesc = ctx.sortDesc;
      this.loadData1();
    },
    loadData4(){
      this.contracts4.isBusy = true;
      this.openHttp("contracts/", this.contracts4.search).then((result) => {
        this.contracts4.isBusy = false;
        this.contracts4.items = result;
      })
    },
    sortingChange4(ctx) {
      this.contracts4.search.sortby = ctx.sortBy;
      this.contracts4.search.sortdesc = ctx.sortDesc;
      this.loadData4();
    },
    filterOpen(){
      this.openHttp("contracts/filters", null ).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      
      this.contracts1.search.page = 1;
      this.contracts1.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.contracts1.search.filters.contractsstatus_id = 1;

      this.contracts4.search.page = 1;
      this.contracts4.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.contracts4.search.filters.contractsstatus_id = 4;

      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        companies_id: null,
        standards_id: null,
        certificationbodies_id: 133,
        nacesiafcodes_id: null,
        errorstypes_id: null
      }
      this.filterSet();
    }
  }
}
</script>