<template>
    <div>
        <b-modal v-if="show" v-model="show" title="Release" size="lg" @hidden="closeForm()" @close="closeForm()">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Certificate" active>
                        <b-row>
                            <b-col lg="8">
                                <b-form-group label="Standard">
                                    <b-form-select v-model="data.release.contractsstandards_id" :options="data.standards" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4">
                                <b-form-group label="Revision">
                                    <b-form-input v-model="data.release.revnumber" type="number"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="3">
                                <b-form-group label="Code">
                                    <b-form-input :value="data.standards.findIndex(x => x.id === data.release.contractsstandards_id) > -1 ? data.standards.find(x => x.id === data.release.contractsstandards_id).certificatecode : null" disabled/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Risk level">
                                    <b-form-select v-model="data.release.riskslevels_id" :options="data.riskslevels" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="3">
                                <b-form-group label="1st Issue Date">
                                    <b-form-input :value="data.standards.findIndex(x => x.id === data.release.contractsstandards_id) > -1 ? data.standards.find(x => x.id === data.release.contractsstandards_id).certificatefirstissue : null" type="date" disabled/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Issue Date">
                                    <b-form-input v-model="data.release.issuedate" type="date"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Valid from Date">
                                    <b-form-input v-model="data.release.validfromdate" type="date"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Expiration Date">
                                    <b-form-input v-model="data.release.expiracydate" type="date"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12">
                                <b-form-group label="Note">
                                    <b-form-textarea v-model="data.release.note" rows="2"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Sites">
                        <b-table no-border-collapse :items="data.release.sites" :fields="fields.sites">
                            <template v-slot:cell(address)="row">
                                {{ data.sites.find(x => x.id === row.item.contractssites_id).address }}
                            </template>
                            <template v-slot:cell(city)="row">
                                {{ data.sites.find(x => x.id === row.item.contractssites_id).city }}
                            </template>
                            <template v-slot:cell(type)="row">
                                <b-form-checkbox v-model="row.item.selectsite" @change="selectCheck(row.item.contractssites_id, $event)">Active</b-form-checkbox>
                                <b-form-checkbox v-model="row.item.ismain" v-show="row.item.selectsite === true" @change="isMainCheck(row.item.contractssites_id, $event)">Main</b-form-checkbox>
                                <b-form-checkbox v-model="row.item.isregistered" v-show="row.item.selectsite === true" @change="isRegisteredCheck(row.item.contractssites_id, $event)">Registred</b-form-checkbox>
                                <b-form-checkbox v-model="row.item.isoperative" v-show="row.item.selectsite === true">Operative</b-form-checkbox>
                            </template>
                            <template v-slot:cell(phases)="row">
                                <b-form-checkbox-group v-show="row.item.selectsite === true" v-model="row.item.contractsphases">
                                    <b-form-checkbox v-for="(item2, index2) of data.contractsphases" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                                </b-form-checkbox-group>
                            </template>
                        </b-table>
                    </b-tab>
                    <b-tab title="Scope">
                        <b-table no-border-collapse :items="data.scopes" :fields="fields.scopes">
                            <template v-slot:cell(languages)="row">
                                <div v-for="(item2, index2) of row.value" :key="index2"><strong>{{ item2.lang }}</strong>: {{ item2.val }}</div>
                            </template>
                            <template v-slot:cell(id)="row">
                                <b-form-radio v-model="data.release.contractsscopes_id" :value="row.value"></b-form-radio>
                            </template>
                        </b-table>
                    </b-tab>
                    <b-tab title="Program">
                        <b-table no-border-collapse :items="data.release.sites.filter(x => x.isoperative === true)" :fields="fields.program">
                            <template v-slot:cell(address)="row">
                                {{ data.sites.find(x => x.id === row.item.contractssites_id).address }}
                            </template>
                            <template v-slot:cell(city)="row">
                                {{ data.sites.find(x => x.id === row.item.contractssites_id).city }}
                            </template>
                            <template v-slot:cell(phases)="row">
                                <b-form-checkbox-group v-show="row.item.selectsite === true" v-model="row.item.contractsphases">
                                    <b-form-checkbox v-for="(item2, index2) of data.contractsphases" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                                </b-form-checkbox-group>
                            </template>
                        </b-table>
                    </b-tab>
                </b-tabs>
            </b-card>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
                    <b-btn variant="danger" v-show="data.release.id > 0" @click="deleteData()">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractrelease",
    data() {
        return {
            show: false,
            data: null,

            fields: {
                scopes:[{
                    key: "languages",
                    label: "Scope",
                },{
                    key: "naces",
                    label: "NACEs",
                },{
                    key: "id",
                    label: "",
                }],
                sites:[{
                    key: "address",
                    label: "Address",
                },{
                    key: "city",
                    label: "City",
                },{
                    key: "type",
                    label: "",
                }],
                program:[{
                    key: "address",
                    label: "Address",
                },{
                    key: "city",
                    label: "City",
                },{
                    key: "phases",
                    label: "Phases",
                }]
            }
        }
    },
    methods: {
        loadData(contractsId, id){
            this.openHttp("contracts/release/", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        duplicateData(){
            this.data.release.id = 0;
            this.data.release.issuedate = null;
        },
        selectCheck(contractssitesId, selectsite){
            if (selectsite === false){
                this.data.release.sites.find(x=> x.contractssites_id === contractssitesId).ismain = false;
                this.data.release.sites.find(x=> x.contractssites_id === contractssitesId).isregistered = false;
                this.data.release.sites.find(x=> x.contractssites_id === contractssitesId).isoperative = false;
            } else {
                this.data.release.sites.find(x=> x.contractssites_id === contractssitesId).ismain = this.data.sites.find(x => x.id === contractssitesId).ismain;
                this.data.release.sites.find(x=> x.contractssites_id === contractssitesId).isregistered = this.data.sites.find(x => x.id === contractssitesId).isregistered;
                this.data.release.sites.find(x=> x.contractssites_id === contractssitesId).isoperative = this.data.sites.find(x => x.id === contractssitesId).isoperative;
            }
        },
        isMainCheck(contractssitesId, isMain){
            if (isMain === true){
                for (let item of this.data.release.sites){
                    if (item.ismain === true && item.contractssites_id != contractssitesId){
                        item.ismain = false;
                    }
                }
            }
        },
        isRegisteredCheck(contractssitesId, isRegistered){
            if (isRegistered === true){
                for (let item of this.data.release.sites){
                    if (item.isregistered === true && item.contractssites_id != contractssitesId){
                        item.isregistered = false;
                    }
                }
            }
        },
        saveData(){
            this.saveHttp("contracts/release/save", this.data.release).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/release/delete", { id: this.data.release.id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>