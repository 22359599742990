<template>
  <div>
    <contract ref="contract" @update="loadData"/>
    <b-modal v-model="showFilter" title="Filters">
      <b-form-group label="Contract">
        <b-form-input v-model="tempFilter.contract"/>
      </b-form-group>
      <b-form-group label="Company">
        <b-form-select v-model="tempFilter.companies_id" :options="addNullCombo(filtersMenu.companies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Standard">
        <b-form-select v-model="tempFilter.standards_id" :options="addNullCombo(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="IAF">
        <b-form-select v-model="tempFilter.nacesiafcodes_id" :options="addNullCombo(filtersMenu.nacesiafcodes)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filter</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Refresh</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filter</b-btn>
    <b-card no-body class="mt-2">
      <b-tabs card>
        <b-tab title="Opened" active>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="contracts2.items.data" :fields="contracts2.fields" :busy="contracts2.isBusy" :no-local-sorting="true" :sort-by.sync="contracts2.search.sortby" :sort-desc.sync="contracts2.search.sortdesc" @sort-changed="sortingChange2" @row-clicked="$refs.contract.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(standards)="row">
              <div v-for="(item, index) of row.item.standards" :key="index">
                {{ item }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="contracts2.isBusy" :currentpage="contracts2.search.page" :total="contracts2.items.total" @updateCurrentPage="contracts2.search.page = $event" @loadData="loadData2"/>
        </b-tab>
        <b-tab title="Closed">
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="contracts3.items.data" :fields="contracts3.fields" :busy="contracts3.isBusy" :no-local-sorting="true" :sort-by.sync="contracts3.search.sortby" :sort-desc.sync="contracts3.search.sortdesc" @sort-changed="sortingChange3" @row-clicked="$refs.contract.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(standards)="row">
              <div v-for="(item, index) of row.item.standards" :key="index">
                {{ item }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="contracts3.isBusy" :currentpage="contracts3.search.page" :total="contracts3.items.total" @updateCurrentPage="contracts3.search.page = $event" @loadData="loadData3"/>
        </b-tab>
        <b-tab title="Failed">
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="contracts5.items.data" :fields="contracts5.fields" :busy="contracts5.isBusy" :no-local-sorting="true" :sort-by.sync="contracts5.search.sortby" :sort-desc.sync="contracts5.search.sortdesc" @sort-changed="sortingChange5" @row-clicked="$refs.contract.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(standards)="row">
              <div v-for="(item, index) of row.item.standards" :key="index">
                {{ item }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="contracts5.isBusy" :currentpage="contracts5.search.page" :total="contracts5.items.total" @updateCurrentPage="contracts5.search.page = $event" @loadData="loadData5"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "contracts",
  data() {
    return {
      showFilter: false,
      tempFilter: {
        id: null,
        contract: null,
        active: true,
        companies_id: null,
        standards_id: null,
        certificationbodies_id: 133,
        nacesiafcodes_id: null,
      },
      filtersMenu: {
        companies:[],
        standards:[],
        certificationbodies: [],
        nacesiafcodes: [],
      },

      contracts2: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            contractsstatus_id: 2,
            companies_id: null,
            contract: null,
            standards_id: null,
            certificationbodies_id: 133,
            nacesiafcodes_id: null,
          },
          sortby: 'issuedate',
          sortdesc: false,
        },
        fields: [{
          key: 'issuedate',
          label: 'Issue',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'company',
          label: 'Company',
          sortable: true,
        },{
          key: 'contract',
          label: 'Contract',
          sortable: true,
        },{
          key: 'standards',
          label: 'Standards',
        },{
          key: 'typecertification',
          label: 'Type',
        },{
          key: 'expiracydate',
          label: 'Expiration',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      contracts3: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            contractsstatus_id: 3,
            companies_id: null,
            contract: null,
            standards_id: null,
            certificationbodies_id: 133,
            nacesiafcodes_id: null,
          },
          sortby: 'issuedate',
          sortdesc: false,
        },
        fields: [{
          key: 'issuedate',
          label: 'Issue',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'company',
          label: 'Company',
          sortable: true,
        },{
          key: 'contract',
          label: 'Contract',
          sortable: true,
        },{
          key: 'standards',
          label: 'Standards',
        },{
          key: 'typecertification',
          label: 'Type',
        },{
          key: 'expiracydate',
          label: 'Expiration',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      contracts5: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            contractsstatus_id: 5,
            companies_id: null,
            contract: null,
            standards_id: null,
            certificationbodies_id: 133,
            nacesiafcodes_id: null,
          },
          sortby: 'issuedate',
          sortdesc: false,
        },
        fields: [{
          key: 'issuedate',
          label: 'Issue',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'company',
          label: 'Company',
          sortable: true,
        },{
          key: 'contract',
          label: 'Contract',
          sortable: true,
        },{
          key: 'standards',
          label: 'Standards',
        },{
          key: 'typecertification',
          label: 'Type',
        },{
          key: 'expiracydate',
          label: 'Expiration',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        }],
        items: {
          total: 0,
          data: [],
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadData2();
      this.loadData3();
      this.loadData5();
    },
    loadData2(){
      this.contracts2.isBusy = true;
      this.openHttp("contracts/", this.contracts2.search ).then((result) => {
        this.contracts2.isBusy = false;
        this.contracts2.items = result;
      })
    },
    loadData3(){
      this.contracts3.isBusy = true;
      this.openHttp("contracts/", this.contracts3.search ).then((result) => {
        this.contracts3.isBusy = false;
        this.contracts3.items = result;
      })
    },
    loadData5(){
      this.contracts5.isBusy = true;
      this.openHttp("contracts/", this.contracts5.search ).then((result) => {
        this.contracts5.isBusy = false;
        this.contracts5.items = result;
      })
    },

    filterOpen(){
      this.openHttp("contracts/filters", null ).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;

      this.contracts2.search.page = 1;
      this.contracts2.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.contracts2.search.filters.contractsstatus_id = 2;

      this.contracts3.search.page = 1;
      this.contracts3.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.contracts3.search.filters.contractsstatus_id = 3;

      this.contracts5.search.page = 1;
      this.contracts5.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.contracts5.search.filters.contractsstatus_id = 5;

      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        contract: null,
        companies_id: null,
        standards_id: null,
        certificationbodies_id: 133,
        nacesiafcodes_id: null
      }
      this.filterSet();
    },
    sortingChange2(ctx) {
      this.contracts2.search.sortby = ctx.sortBy;
      this.contracts2.search.sortdesc = ctx.sortDesc;
      this.loadData2();
    },
    sortingChange3(ctx) {
      this.contracts3.search.sortby = ctx.sortBy;
      this.contracts3.search.sortdesc = ctx.sortDesc;
      this.loadData3();
    },
    sortingChange5(ctx) {
      this.contracts5.search.sortby = ctx.sortBy;
      this.contracts5.search.sortdesc = ctx.sortDesc;
      this.loadData5();
    }
  }
}
</script>