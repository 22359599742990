<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Qualification" scrollable @hidden="closeForm()" @close="closeForm()">
      <document ref="document" @update="loadData(data.qualification.id)"/>
      <signature ref="signature" @update="loadData(data.qualification.id)"/>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Person">
            <b-form-select v-model="data.qualification.users_id" :options="data.users2" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Standard">
            <b-form-select v-model="data.qualification.standards_id" :options="data.standards.filter(x => x.certificationbodies.includes(133))" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Role">
            <b-form-select v-model="data.qualification.usersroles_id" :options="data.usersroles" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Date">
            <b-form-input type="date" v-model="data.qualification.fromdate"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Approved by">
            <b-form-input :value="data.qualification.approve_users_id != null ? data.approve_users.find(x => x.id === data.qualification.approve_users_id).val : ''"/>
          </b-form-group>
        </b-col>
        <b-col lg="2" v-if="data.qualification.signatures_id > 0">
          <b-form-group label="Signature">
            <b-btn :variant="data.qualification.signatures_check === true ? 'success' : 'danger'" @click="$refs.signature.loadData(data.qualification.signatures_id)">{{ data.qualification.signatures_check === true ? 'Signed' : 'To be signed' }}</b-btn>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table v-if="data.usersroles.find(x => x.id === data.qualification.usersroles_id).neednace === true" responsive :fields="fields.qualifications" :items="data.naces" :sticky-header="(winHeight - 335)+ 'px'">
        <template v-slot:cell(naces)="row">
          <b-btn v-for="(item, index) of row.value" :key="index" size="sm" class="mr-1 mb-1" :variant="data.qualification.naces.includes(item.id) ? 'success':'secondary'" @click="addRemoveNace(item.id)" :disabled="!item.accreditated">
            <div class="mt-2">
              <b-badge variant="light">{{ item.val }} <b-progress :value="Math.round(experiences.filter(x => x.nacesiafcodes_id === row.item.id).map(x => x.score * item.multiplier).reduce((partialSum, a) => partialSum + a, 0))" :variant="Math.round(experiences.filter(x => x.nacesiafcodes_id === row.item.id).map(x => x.score * item.multiplier).reduce((partialSum, a) => partialSum + a, 0)) >= 100 ? 'success' : (Math.round(experiences.filter(x => x.nacesiafcodes_id === row.item.id).map(x => x.score * item.multiplier).reduce((partialSum, a) => partialSum + a, 0)) >= 80 ? 'warning' : 'danger')"></b-progress></b-badge>
            </div>
          </b-btn>
        </template>
        <template #cell(show_details)="row">
          <b-btn size="sm" @click="row.toggleDetails">{{ row.detailsShowing ? 'Hide' : 'Show'}}</b-btn>
        </template>
        <template #row-details="row">
          <b-table responsive :fields="fields.experiences" :items="experiences.filter(x => x.nacesiafcodes_id === row.item.id)" :sticky-header="(winHeight / 3)+ 'px'">
            <template v-slot:cell(score)="row2">
              <div v-for="(item, index) of row.item.risks" :key="index">{{ item.level }}: {{ row2.value }} x {{ item.multiplier }} = {{ row2.value * item.multiplier }}</div>
            </template>
          </b-table>
          <div v-for="(item, index) of row.item.risks" :key="index">
            {{ item.level }} risk level: {{ Math.round(experiences.filter(x => x.nacesiafcodes_id === row.item.id).map(x => x.score).reduce((partialSum, a) => partialSum + a, 0) * item.multiplier) / item.multiplier }} x {{ item.multiplier }} = {{ Math.round(experiences.filter(x => x.nacesiafcodes_id === row.item.id).map(x => x.score).reduce((partialSum, a) => partialSum + a, 0) * item.multiplier) }}
          </div>
        </template>
      </b-table>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn v-if="data.qualification.id > 0" variant="warning" class="mr-2" @click="extendData()">Extend</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" @click="deleteData()">Delete</b-btn>
          <b-btn variant="warning" class="mr-2" @click="loadScores()">Check</b-btn>
          <b-btn v-if="data.qualification.id > 0" variant="info" class="mr-2" @click="$refs.document.generateData(data.qualification.id)">Generate</b-btn>
          <b-btn v-if="data.qualification.documents_id > 0" variant="primary" @click="$refs.document.downloadData(data.qualification.documents_id)">Download</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';
import signature from './signature.vue';

export default {
  name: "qualification",
  components: { document, signature },
  data() {
    return {
      show: false,
      data: null,
      experiences: [],
      fields: {
        qualifications: [{
          key: 'iafcode',
          label: "IAF",
          sortable: true,
        },{
          key: 'naces',
          label: 'NACEs'
        },{
          key: "show_details",
          label: ""
        }],
        experiences: [{
          key: "fromdate",
          label: "From",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "todate",
          label: "To",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "experiencetype",
          label: "Type",
        },{
          key: "issuer",
          label: "Issuer",
        },{
          key: "score",
          label: "Score"
        },{
          key: "details",
          label: "Details"
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    addRemoveNace(naceId){
      if (this.data.qualification.naces.includes(naceId)){
        this.data.qualification.naces.splice(this.data.qualification.naces.indexOf(naceId),1);
      } else {
        this.data.qualification.naces.push(naceId)
      }
    },
    extendData(){
      let today = new Date()
      this.data.qualification.id = 0;
      this.data.qualification.fromdate = [today.getFullYear(), today.getMonth() + 1, today.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
      this.data.qualification.todate = null;
      this.loadScores();
    },
    loadData(id){
      this.openHttp("qualifications/qualification/", {id: id}).then((result) => {
        this.data = result;
        this.show = true;
        this.loadScores();
      })
    },
    loadScores(){
      this.openHttp("qualifications/qualification/scores", this.data.qualification).then((result) => {
        this.experiences = result;
      })
    },
    saveData(){
      this.saveHttp("qualifications/qualification/save", this.data.qualification).then((result) => {
        if (result !== null) this.loadData(result);
      })
    },
    generateData(){
      this.saveHttp("qualifications/qualification/generate", {id: this.data.qualification.users_id});
    },
    deleteData(){
      this.deleteHttp("qualifications/qualification/delete", {id: this.data.qualification.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>