<template>
  <div>
    <documentdocument ref="documentdocument" @update="loadData"/>
    <document ref="document"/>
    <b-modal v-model="documents.showFilter" title="Filters">
      <b-form-group label="Code / Name">
        <b-form-input v-model="documents.tempFilter.code"/>
      </b-form-group>
      <b-form-group label="Status">
        <b-form-select v-model="documents.tempFilter.active" :options="addNullCombo(activeCombo)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Type">
        <b-form-select v-model="documents.tempFilter.external" :options="addNullCombo(typeCombo)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSetDocuments">Filter</b-btn>
          <b-btn variant="warning" @click="filterResetDocuments">Reset</b-btn>
        </div>
      </template>
    </b-modal>    
    <b-btn class="mr-2" variant="primary" @click="loadData()">Refresh</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpenDocuments()">Filter</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.documentdocument.loadData(0)">New</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="documents.items.data" :fields="documents.fields" :busy="documents.isBusy" :no-local-sorting="true" :sort-by.sync="documents.search.sortby" :sort-desc.sync="documents.search.sortdesc" @sort-changed="sortingDocumentsChange" @row-clicked="$refs.documentdocument.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
    </b-table>
    <tablebottom :currentpage="documents.search.page" @updateCurrentPage="documents.search.currentpage = $event" :total="documents.items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import document from './document.vue';
import documentdocument from './documentdocument.vue';

export default {
  name: "documentssystem",
  components: { document, documentdocument },
  data() {
    return {
      activeCombo: [{
        id: true,
        val: "Active"
      },{
        id: false,
        val: "Not active"
      }],
      typeCombo: [{
        id: false,
        val: "Internal"
      },{
        id: true,
        val: "External"
      }],
      documents: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            active: true,
            code: null,
            external: null,
          },
          sortby: "",
          sortdesc: false,
        },
        tempFilter: {
          active: true,
          code: null,
          external: null,
        },        
        fields: [{
          key: "code",
          label: "Code",
          sortable: true,
        },{
          key: "documentname",
          label: "Name",
          sortable: true,
        },{
          key: "documenttype",
          label: "Type",
          sortable: true,
        },{
          key: "active",
          label: "Status",
          sortable: true,
          formatter: value => {
            return value === true ? "Active" : "Not active";
          }
        },{
          key: "external",
          label: "Type",
          sortable: true,
          formatter: value => {
            return value === true ? "External" : "Internal";
          }
        },{
          key: "revision",
          label: "Rev."
        },{
          key: "create_date",
          label: "Creation",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "approve_date",
          label: "Approval",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "expiry_date",
          label: "Expiry",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'documentssystemrevisions_id',
          label: "",
        }],
        items: {
          total: 0,
          data: [],
        },
      }
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.documents.isBusy = true;
      this.openHttp("certificationbody/documentssystem/", this.documents.search).then((result) => {
        this.documents.items = result;
        this.documents.isBusy = false;
      })
    },
    filterOpenDocuments(){
      this.documents.showFilter = true;
    },
    filterSetDocuments(){
      this.documents.showFilter = false;
      this.documents.search.page = 1;
      this.documents.search.filters = this.documents.tempFilter;
      this.loadData();
    },
    filterResetDocuments(){
      this.documents.tempFilter = {
        active: true,
        code: null,
        external: null
      };
      this.filterSetDocuments();
    },
    sortingDocumentsChange(ctx) {
      this.documents.search.sortby = ctx.sortBy;
      this.documents.search.sortdesc = ctx.sortDesc;
      this.loadData();
    },
    downloadRevision(id){
      this.$refs.revisionId.value = id;
      this.$refs.formdownload.submit();
    },
    downloadRecord(recordsId){
      this.$refs.document.downloadRecord( recordsId );
    }
  }
}
</script>