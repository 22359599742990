<template>
    <div>
        <b-modal v-if="show" v-model="show" title="Decision" size="lg" @hidden="closeForm()" @close="closeForm()">
            <b-row>
                <b-col lg="9">
                    <b-form-group label="Standard">
                        <b-form-select v-model="data.history.contractsstandards_id" :options="data.contractsstandards" value-field="id" text-field="val" :disabled="data.history.contractsstandardsreleases_id != null"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Revision">
                        <b-form-select v-model="data.history.contractsstandardsreleases_id" :options="data.contractsstandardsreleases.filter(x => x.contractsstandards_id == data.history.contractsstandards_id)" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="3">
                    <b-form-group label="State">
                        <b-form-select v-model="data.history.contractsstandardsstatus_id" :options="data.contractsstandardsstatus" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Accreditation">
                        <b-form-select v-model="data.history.accreditated" :options="[{id: false, val: 'No'},{id: true, val: 'Yes'}]" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group label="Check">
                        <b-btn :variant="checkAccreditation == 'Verified' ? 'success' : 'danger'">{{ checkAccreditation }}</b-btn>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Note">
                <b-form-textarea v-model="data.history.note" rows="4"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
                    <b-btn variant="danger" v-show="data.history.id > 0" @click="deleteData()">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contracthistory",
    data() {
        return {
            show: false,
            data: null
        }
    },
    computed: {
        checkAccreditation (){
            if (this.data != null){
                if (this.data.accreditationcheck.findIndex(x => x.id === this.data.history.contractsstandardsreleases_id) > -1){                
                    const tmp = this.data.accreditationcheck.find(x => x.id === this.data.history.contractsstandardsreleases_id);
                    if (tmp.nacesko === null && tmp.nacesok !== null){
                        return "Verified";
                    } else {
                        return "Not Verified (Accreditated: " + tmp.nacesok + ", not accreditated: " + tmp.nacesko + ")";
                    } 
                }
            }
            return null;
        },
    },
    methods: {
        loadData(contractsId, id){
            this.openHttp("contracts/history/", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/history/save", this.data.history).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/history/delete", this.data.history).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>