<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Evaluation" size="xl" @hidden="closeForm()" @close="closeForm()">
      <document ref="document"/>
      <b-row>
        <b-col lg="4">
          <b-form-group label="Candidate">
            <b-form-select v-model="data.evaluation.users_id" :options="data.users" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Date">
            <b-form-input type="date" v-model="data.evaluation.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Evaluated by">
            <b-form-select v-model="data.evaluation.review_users_id" :options="data.users" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Evaluation">
        <b-table no-border-collapse responsive :sticky-header="(winHeight - 375) + 'px'" :items="data.evaluation.id > 0 ? data.requirements.filter(x => data.evaluation.requirements.map(x => x.id).includes(x.id)) : (data.requirements.filter(x => data.users.findIndex(y => y.id === data.evaluation.users_id) > -1 ? data.users.find(y => y.id === data.evaluation.users_id).requirements.includes(x.id) : false))" :fields="fields">
          <template v-slot:cell(requirement)="row">
            <b>{{ row.value }}</b>
            <div>{{ row.item.evaluationmethod }}</div>
            <b-btn v-show="(data.evaluation.requirements.findIndex(x => x.id === row.item.id) > -1 ? data.evaluation.requirements.find(x => x.id === row.item.id).passed : null) === null" variant="info" @click="evalutateData(row.item.id)">cheat</b-btn>
          </template>
          <template v-slot:cell(passed)="row">
            <b-form-select :value="data.evaluation.requirements.findIndex(x => x.id === row.item.id) > -1 ? data.evaluation.requirements.find(x => x.id === row.item.id).passed : null" :options="yesNoCombo" value-field="id" text-field="val" @change="uploadPassed(row.item.id, $event)"/>
          </template>
          <template v-slot:cell(note)="row">
            <b-form-textarea :value="data.evaluation.requirements.findIndex(x => x.id === row.item.id) > -1 ? data.evaluation.requirements.find(x => x.id === row.item.id).note : null" @change="uploadNote(row.item.id, $event)" rows="5" max-rows="10"/>
          </template>
        </b-table>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.evaluation.id > 0" @click="deleteData()">Delete</b-btn>
          <b-btn variant="info" v-show="data.evaluation.id > 0" @click="$refs.document.generateDataRequirements(data.evaluation.id)">Generate</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';

export default {
  name: "requirementevaluation",
  components: { document },
  data() {
    return {
      show: false,
      data: null,
      yesNoCombo: [{
        id: null,
        val: "Not evaluated"
      },{
        id: true,
        val: "Positive"
      },{
        id: false,
        val: "Negative"
      }],
      fields: [{
        key: "requirement",
        label: "Requirement",
        sortable: true,
        thStyle: { width: "40%" }
      },{
        key: "passed",
        label: "Status",
        thStyle: { width: "10%" }
      },{
        key: "note",
        label: "Note",
        thStyle: { width: "50%" }
      }],
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData(id, usersId = null){
      this.openHttp("certificationbody/requirementsevaluation/", {id: id, users_id: usersId}).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    uploadPassed(requirementsId, passedVal){
      if (this.data.evaluation.requirements.findIndex(x => x.id === requirementsId) === -1 ){
        this.data.evaluation.requirements.push({
          id: requirementsId,
          passed: null,
          note: null
        });
      }
      this.data.evaluation.requirements.find(x => x.id === requirementsId).passed = passedVal;
      if (passedVal === null) this.data.evaluation.requirements.find(x => x.id === requirementsId).note = null;
    },
    uploadNote(requirementsId, noteVal){
      if (this.data.evaluation.requirements.findIndex(x => x.id === requirementsId) === -1 ){
        this.data.evaluation.requirements.push({
          id: requirementsId,
          passed: null,
          note: null
        });
      }
      this.data.evaluation.requirements.find(x => x.id === requirementsId).note = noteVal;
    },
    evalutateData(requirementsId){
      this.saveHttp("certificationbody/requirementsevaluation/evaluate", {id: requirementsId, users_id: this.data.evaluation.users_id, date: this.data.evaluation.date}).then((result) => {
        if (this.data.evaluation.requirements.findIndex(x => x.id === requirementsId) === -1 ){
          this.data.evaluation.requirements.push({
            id: requirementsId,
            passed: null,
            note: null
          });
        }
        this.data.evaluation.requirements.find(x => x.id === requirementsId).passed = result.passed;
        if (result.note == null) result.note = "";
        this.data.evaluation.requirements.find(x => x.id === requirementsId).note = result.note.replace("\\n", "\n");
      })
    },
    saveData(){
      this.saveHttp("certificationbody/requirementsevaluation/save", this.data.evaluation).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    deleteData(){
      this.saveHttp("certificationbody/requirementsevaluation/delete", this.data.evaluation).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>