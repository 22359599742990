<template>
  <div>
    <b-modal v-if="data != null" v-model="show" title="Revision" scrollable @hidden="closeForm()" @close="closeForm()">
      <document ref="document" @update="loadData(data.revision.id, data.revision.documentssystem_id)"/>
      <b-form-group label="Created by">
        <b-form-select v-model="data.revision.create_users_id" :options="data.users" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Created date">
        <b-form-input type="date" v-model="data.revision.create_date"/>
      </b-form-group>
      <b-form-group label="Revision number">
        <b-form-input v-model="data.revision.revision"/>
      </b-form-group>
      <b-form-group label="Approved by" v-if="data.revision.id > 0">
        <b-form-select v-model="data.revision.approve_users_id" :options="data.users" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Approve date" v-if="data.revision.id > 0">
        <b-form-input type="date" v-model="data.revision.approve_date"/>
      </b-form-group>
      <b-form-group label="Expiry date" v-if="data.revision.id> 0">
        <b-form-input type="date" v-model="data.revision.expiry_date"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-if="data.revision.id > 0" class="mr-2" @click="loadData(data.revision.id, data.revision.documentssystem_id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.revision.id > 0" @click="deleteData()">Delete</b-btn>
          <b-btn variant="info" class="mr-2" v-if="data.revision.id > 0" @click="$refs.document.loadData(data.document.id)">Upload</b-btn>
          <b-btn variant="primary" v-if="data.revision.id > 0" v-show="data.document.check === true" @click="$refs.document.downloadData(data.document.id)">Download</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';

export default {
  name: "documentrevision",
  components: { document },
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData(id, documentsId){
      this.openHttp("certificationbody/documentssystem/documentrevision/", { id: id, documentssystem_id: documentsId }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("certificationbody/documentssystem/documentrevision/save", this.data.revision).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("certificationbody/documentssystem/documentrevision/delete", { id: this.data.revision.id }).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>
