<template>
  <div>
    <impartialitycommittee ref="impartialitycommittee" @update="loadData()"/>
    <document ref="document"/>
    <b-btn class="mr-2" variant="primary" @click="loadData">Refresh</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.impartialitycommittee.loadData(0)">New</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.impartialitycommittee.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
      <template v-slot:cell(documents_id)="row">
        <b-btn v-show="row.value > 0" variant="primary" size="sm" @click="$refs.document.downloadData(row.value)">Download</b-btn>
      </template>
    </b-table>
    <tablebottom :currentpage="search.page" @updateCurrentPage="search.page = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import document from './document.vue';
import impartialitycommittee from './impartialitycommittee.vue';

export default {
  name: "impartialitycommittees",
  components: { document, impartialitycommittee },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search:{
        page: 1,
        filters: {},
        sortby: "fromdate",
        sortdesc: true,
      },
      tempFilter: {},
      filtersMenu: {},
      fields: [{
        key: 'date',
        label: "Date",
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
        sortable: true
      },{
        key: 'users',
        label: 'Participants',
      },{
        key: 'documents_id',
        label: ""
      }],
      items: {
        total: 0,
        data: [],
      }
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.openHttp("certificationbody/impartialitycommittees/", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>