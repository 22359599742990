<template>
  <div>
    <b-row>
      <b-col lg="4">
        <b-form-group label="Number of accredited certificates valid at the end of December">
          <b-table no-border-collapse responsive :items="kpis.certificatesvalid" :fields="fields"/>
        </b-form-group>              
      </b-col>
      <b-col lg="4">
        <b-form-group label="Number of accredited certificates issued at the end of December">
          <b-table no-border-collapse responsive :items="kpis.certificatesissued" :fields="fields"/>
        </b-form-group>              
      </b-col>
      <b-col lg="4">
        <b-form-group label="Number of auditors">
          <b-table no-border-collapse responsive :items="kpis.auditors" :fields="fields"/>
        </b-form-group>              
      </b-col>
      <b-col lg="4">
        <b-form-group label="Number of transfers accepted">
          <b-table no-border-collapse responsive :items="kpis.transfers" :fields="fields"/>
        </b-form-group>              
      </b-col>
      <b-col lg="4">
        <b-form-group label="Number of overdue audits">
          <b-table no-border-collapse responsive :items="kpis.overdueaudits" :fields="fields"/>
        </b-form-group>              
      </b-col>
      <b-col lg="4">
        <b-form-group label="Number of auditor-days delivered">
          <b-table no-border-collapse responsive :items="kpis.dates" :fields="fields"/>
        </b-form-group>              
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "kpis",
  data() {
    return {
      isBusy: true,
      kpis: {
        certificatesvalid: [],
        certificatesissued: [],
        auditors: [],
        overdueaudits: [],
        transfers: [],
        dates: []
      },
      fields: [{
        key: 'year',
        label: 'Year',
        sortable: true,
      },{
        key: 'kpi',
        label: 'KPI',
      }]
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){      
      this.isBusy = true;
      this.openHttp("certificationbody/kpis/", this.search).then((result) => {
        this.kpis = result;
        this.isBusy = false;
      })
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>
