<template>
    <div>
        <b-modal v-if="show" v-model="show" title="Standard" @hidden="closeForm" @close="closeForm">
            <b-form-group label="Standard">
                <b-form-select v-model="data.standard.standards_id" :options="data.standards" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Certificate code">
                <b-form-input v-model="data.standard.certificatecode"/>
            </b-form-group>
            <b-form-group label="1st issue">
                <b-form-input v-model="data.standard.certificatefirstissue" type="date"/>
            </b-form-group>
            <b-form-group label="Initial issue">
                <b-form-select v-model="data.standard.firstcertificatewithanothercb" :options="firstcertificatewithanothercbCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" v-show="data.standard.id > 0" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractstandard",
    data() {
        return {
            show: false,
            data: null,
            firstcertificatewithanothercbCombo: [{
                id: false,
                val: "Same CB"
            },{
                id: true,
                val: "Other CB"
            }]
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.openHttp("contracts/standard/", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/standard/save", this.data.standard).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/standard/delete", { id: this.data.standard.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>