<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Audit log" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Form" active>
            <b-row>
              <b-col lg="5">
                <b-form-group label="Company">
                  <b-form-input v-model="data.auditlog.company"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Certificationbody">
                  <b-form-input v-model="data.auditlog.certificationbody"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Date">
                  <b-form-input v-model="data.auditlog.datereport" type="date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Role">
                  <b-form-select v-model="data.auditlog.usersroles_id" :options="data.usersroles" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Under accreditation">
                  <b-form-select v-model="data.auditlog.underaccreditation" :options="underAccreditationCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Mandays">
                  <b-form-input v-model="data.auditlog.manday" min="0" step="0.0001" type="number"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Step">
                  <b-form-select v-model="data.auditlog.contractsphases_id" :options="data.contractsphases" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Standards">
              <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.auditlog.standards = updateCombo(standardsSupport)"/>
            </b-form-group>
            <b-form-group label="IAF">
              <multiselect v-model="nacesiafcodesSupport" :options="data.nacesiafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.auditlog.nacesiafcodes = updateCombo(nacesiafcodesSupport)"/>
            </b-form-group>
            <b-form-group label="Dates">
              <b-btn variant="primary" size="sm" @click="addDate()">Add</b-btn>
              <b-row class="mt-3">
                <b-col lg="3" class="mb-1" v-for="(item, index) in data.auditlog.dates" :key="index">
                  <b-input-group size="sm">
                    <b-form-input type="date" :value="item"/>
                    <template #append>
                      <b-btn variant="danger" @click="deleteDate(index)">Delete</b-btn>
                    </template>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label="Scope">
              <b-input-group v-for="item of data.auditlog.languages" :key="item.id" v-show="item.id === 2">
                <b-form-textarea v-model="item.scope" rows="3"/>
              </b-input-group>
            </b-form-group>
          </b-tab>
          <b-tab :title="'Documents ' + data.documents.length" :disabled="data.auditlog.id === 0">
            <document ref="document" @update="loadData(data.auditlog.users_id, data.auditlog.id)"/>
            <b-btn variant="primary" @click="loadDocument(null)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive class="mt-3" :items="data.documents" :fields="fields" @row-clicked="loadDocument($event.file)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadFile(row.value)">Download</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.auditlog.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';

export default {
  name: "auditlog",
  components: { document },
  data() {
    return {
      show: false,
      data: null,
      standardsSupport: [],
      nacesiafcodesSupport: [],
      underAccreditationCombo: [{
        id: false,
        val: "No"
      },{
        id: true,
        val: "Yes"
      }],
      fields: [{
        key: 'filename',
        label: "Document",
      },{
        key: 'id',
        label: "",
      }],
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(usersId, id){
      this.openHttp("qualifications/curriculum/auditlog/", { id: id, users_id: usersId }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.auditlog.standards);
        this.nacesiafcodesSupport = this.convertCombo(result.nacesiafcodes, result.auditlog.nacesiafcodes);
        this.data = result
        this.show = true;
      })
    },
    loadDocument(fileName){
      this.$refs.document.loadData("auditslogs", this.data.auditlog.id, fileName);
    },
    addDate(){
      let d = new Date();
      this.data.auditlog.dates.push([d.getFullYear(), d.getMonth() + 1, d.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-'));
    },
    deleteDate(index){
      this.data.auditlog.dates.splice(index, 1);
    },
    saveData(){
      this.saveHttp("qualifications/curriculum/auditlog/save", this.data.auditlog).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("qualifications/curriculum/auditlog/delete", { id: this.data.auditlog.id, users_id: this.data.auditlog.users_id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(reload = false){
      this.data = null;
      this.show = false;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>