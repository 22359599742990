<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Appointment" size="xl" scrollable @hidden="closeForm()" @close="closeForm()">
      <requirementevaluation ref="requirementevaluation" @update="loadData(data.appointment.id)"/>
      <signature ref="signature" @update="loadData(data.appointment.id)"/>
      <document ref="document" @update="loadData(data.appointment.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Appointment" active>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Jobs">
                  <multiselect v-model="jobsSupport" :options="data.jobs" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.appointment.jobs = updateCombo(jobsSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="12">
                <b-form-group label="Office">
                  <b-form-input v-model="data.appointment.office" :disabled="data.appointment.internal === true"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Appointed person">
                  <b-form-select v-model="data.appointment.users_id" :options="data.users" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Creation date">
                  <b-form-input type="date" v-model="data.appointment.creation_date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2" v-if="data.appointment.id > 0">
                <b-form-group label="Signature">
                  <b-btn :variant="data.appointment.signatures_check1 === true ? 'success' : 'danger'" @click="$refs.signature.loadData(data.appointment.signatures_id1)">{{ data.appointment.signatures_check1 === true ? 'Signed' : 'To be signed' }}</b-btn>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Evaluation & Approval" v-if="data.appointment.id > 0">
            <b-table no-border-collapse responsive show-empty :sticky-header="(winHeight - 435) + 'px'" :items="data.requirements" :fields="fields.requirements">
              <template v-slot:cell(evaluations)="row">
                <div v-for="(item, index) of row.value" :key="index" class="mb-2">
                  <b-btn size="sm" :variant="item.passed === true ? 'success' : (item.passed === false ? 'danger' : 'warning')" @click="$refs.requirementevaluation.loadData(item.id, data.appointment.users_id)">Evaluation {{ (item.passed === true ? 'Passed' : (item.passed === false ? 'Not Passed' : 'To be evaluated')) + ", performed by " + item.fullname + " in date " + item.date }}</b-btn>
                </div>
              </template>
              <template v-slot:empty>There are no requirements</template>
            </b-table>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Approval person">
                  <b-form-select v-model="data.appointment.approval_users_id" :options="addNullCombo(data.users2, 'Select approval person')" value-field="id" text-field="val" :disabled="level===1"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Approval date">
                  <b-form-input type="date" v-model="data.appointment.approval_date" :disabled="level===1"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Signature">
                  <b-btn :variant="data.appointment.signatures_check2 === true ? 'success' : 'danger'" @click="$refs.signature.loadData(data.appointment.signatures_id2)" :disabled="level===1">{{ data.appointment.signatures_check2 === true ? 'Signed' : 'To be signed' }}</b-btn>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Cancellation" v-if="data.appointment.id > 0">
            <b-row >
              <b-col lg="4">
                <b-form-group label="Cancel person">
                  <b-form-select v-model="data.appointment.cancel_users_id" :options="addNullCombo(data.users2, 'Select cancel person')" value-field="id" text-field="val" :disabled="level===1"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Cancel date">
                  <b-form-input type="date" v-model="data.appointment.cancel_date" :disabled="level===1"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Signature">
                  <b-btn :variant="data.appointment.signatures_check3 === true ? 'success' : 'danger'" @click="$refs.signature.loadData(data.appointment.signatures_id3)" :disabled="level===1">{{ data.appointment.signatures_check3 === true ? 'Signed' : 'To be signed' }}</b-btn>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.appointment.id > 0" class="mr-2" @click="loadData(data.appointment.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.appointment.id > 0" @click="deleteData()" :disabled="level===1">Delete</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.appointment.id > 0 && level >= 2" @click="$refs.document.generateDataAppointments(data.appointment.id)">Generate</b-btn>
          <b-btn variant="primary" v-show="data.appointment.id > 0 && level >= 2" @click="$refs.requirementevaluation.loadData(0, data.appointment.users_id)">Requirements evaluation</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import requirementevaluation from './requirementevaluation.vue';
import signature from './signature.vue';
import document from './document.vue';

export default {
  name: "appointment",
  components: { requirementevaluation, signature, document },
  data() {
    return {
      show: false,
      data: null,
      jobsSupport: [],
      fields: {
        requirements: [{
          key: "requirement",
          label: "Requirement",
          sortable: true,
        },{
          key: "evaluations",
          label: "Evaluations",
        }],
        evaluations: [{
          key: "date",
          label: "Date",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "fullname",
          label: "Evaluated by",
          sortable: true,
        }]
      }
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "jobsappointments").accesslevel;
    }
  },
  methods: {
    loadData(id){
      this.openHttp("certificationbody/jobappointment/", { id: id }).then((result) => {
        this.jobsSupport = this.convertCombo(result.jobs, result.appointment.jobs);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("certificationbody/jobappointment/save", this.data.appointment).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    generateData(){
      this.saveHttp("certificationbody/jobappointment/generate", { id: this.data.appointment.id });
    },
    deleteData(){
      this.deleteHttp("certificationbody/jobappointment/delete", { id: this.data.appointment.id }).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>