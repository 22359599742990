<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="'Evaluation ' + data.fullname + ' ' + data.date" @hidden="closeForm()" @close="closeForm()" hide-footer>
      <b-table no-border-collapse :sticky-header="(winHeight - 175) + 'px'" responsive :items="data.questions" :fields="fields" :tbody-tr-class="rowClass" >
        <template v-slot:cell(question)="row">
          <b>
            {{ row.item.standards }} <br>
            IAF: {{ row.item.iafcode }}
          </b>
          <br>
          {{ row.item.question }}
        </template>
        <template v-slot:cell(answer)="row">
          <b-form-textarea size="sm" v-model="row.item.answer" rows="3" max-rows="5" @change="saveData(row.item.nacesiafcodes_id, row.item.competencesquestions_id, $event)" :disable="row.item.correct === true" :disabled="row.item.correct === true"/>
          <b-alert show variant="danger" v-show="row.item.correct === false" class="mt-1"><b>Note: </b>{{ row.item.correctnote }}</b-alert>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "evaluation",
  data() {
    return {
      show: false,
      data: null,
      fields:[{
        key: 'question',
        label: 'Question',
        thStyle: { width: "40%" }
      },{
        key: 'answer',
        label: 'Answer',
        thStyle: { width: "60%" }
      }]
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClass(item, type){
      if (!item || type !== 'row') return "";
      return "table-" + ( item.correct === false ? "danger" : ( item.correct === true ? "success" : ""));
    },
    loadData(id){
      this.openHttp("certificationbody/competenceevaluation/", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(nacesiafcodesId, competencesquestionsId, answer){
      this.saveHttp("certificationbody/competenceevaluation/save", { competencesusersevaluations_id: this.data.id, nacesiafcodes_id: nacesiafcodesId, competencesquestions_id: competencesquestionsId, answer: answer}).then((result) => {
        if (result !== null) this.loadData(this.data.id);
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit("update");
    }
  },
}
</script>