<template>
  <div>
    <div v-if="show">
      <b-form-group label="Sign here">
        <VueSignaturePad v-if="showSignature" id="signature" width="760px" height="400px" ref="signature" :options="{ onEnd }" :customStyle="{ border: 'black 3px solid' }"/>
      </b-form-group>
      <b-form-group label="QR code">
        <qrcode :value="myUrl" :options="{ width: 175 }"></qrcode>
      </b-form-group>
      <div class="w-100">
        <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>          
        <b-btn variant="warning" class="mr-2" v-show="data.signature !== null" @click="cleanData()">Clean</b-btn>
        <b-btn variant="danger" v-show="data.signature !== null" @click="deleteData()">Delete</b-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "externalsignature",
  props: ["token"],
  data() {
    return {
      show: false,
      data: null,
      showSignature: false
    }
  },
  computed: {
    myUrl() {
      return window.location.href;
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.loadData();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize(){
      if (this.showSignature === true){
        this.$refs.signature.clearSignature();
        this.launchSignature();
      }
    },
    onEnd(){
      const { isEmpty, data } = this.$refs.signature.saveSignature();
      if ( isEmpty === false ) this.data.signature = data;
    },
    launchSignature(){
      this.$refs.signature.fromDataURL(this.data.signature, {width: 760, height: 400});      
    },
    loadData(){
      this.showSignature = false;
      this.show = false;
      this.openHttp("checklists/externalsignature/", { token: this.token }).then((result) => {
        this.data = result;
        this.show = true;
        var self = this;
        setTimeout(function(){
          self.showSignature = true;
          setTimeout(function(){ 
            self.launchSignature();
          }, 300);
        }, 300);
      })
    },
    cleanData(){
      this.$refs.signature.clearSignature();
    },
    saveData(){
      this.saveHttp("checklists/externalsignature/save", { token: this.token, signature: this.data.signature }).then((result) => {
        if ( result !== null ) {
          setTimeout(() => window.close(), 1500);
        }
      })
    },
    deleteData(){
      this.deleteHttp("checklists/externalsignature/delete", { token: this.token }).then((result) => {
        if ( result !== null ) {
          setTimeout(() => window.close(), 1500);
        }
      })
    }
  }
}
</script>