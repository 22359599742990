<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" size="lg" title="Interested party" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Interested party">
        <b-form-input v-model="data.interestedparty.interestedparty" required/>
      </b-form-group>
      <b-form-group label="Primary expectations">
        <b-form-textarea v-model="data.interestedparty.primaryexpectations" rows="4"/>
      </b-form-group>
      <b-form-group label="Secondary expectations">
        <b-form-textarea v-model="data.interestedparty.secondaryexpectations" rows="4"/>
      </b-form-group>
      <b-form-group label="Not satisfied expectations">
        <b-form-textarea v-model="data.interestedparty.notsatisfiedexpectations" rows="4"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.interestedparty.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "interestedparty",
  data() {
    return {
      show: false,
      data: null,
    }  
  },
  methods: {
    loadData(id){
      this.openHttp("certificationbody/interestedparty/", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("certificationbody/interestedparty/save", this.data.interestedparty).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("certificationbody/interestedparty/delete", { id: this.data.interestedparty.id }).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>