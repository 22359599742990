<template>
  <div>
    <certificate ref="certificate" @update="loadData"/>
    <b-modal v-model="showFilter" title="Filters">
      <b-form-group label="Decision">
        <b-form-select v-model="tempFilter.lastversion" :options="[{id:null,val:'See all'},{id:true,val:'Last'},{id:false,val:'Not last'}]" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Certificate code">
        <b-form-input v-model="tempFilter.certificatecode"/>
      </b-form-group>
      <b-form-group label="Company">
        <b-form-select v-model="tempFilter.companies_id" :options="addNullCombo(filtersMenu.companiesbg)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Status">
        <b-form-select v-model="tempFilter.contractsstandardsstatus_id" :options="addNullCombo(filtersMenu.contractsstandardsstatus)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Standard">
        <b-form-select v-model="tempFilter.standards_id" :options="addNullCombo(filtersMenu.standardsbg)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filter</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Refresh</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filter</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="computedData" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.certificate.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData"/>
  </div>
</template>

<script>
import certificate from './certificate.vue';

export default {
  name: "certificates",
  components : {
    certificate
  },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          lastversion: null,
          certificatecode: null,
          companies_id: null,
          contractsstandardsstatus_id: null,
          standards_id: null,
          certificationbodies_id: 133
        },
        sortby: 'expiracydate',
        sortdesc: false,
      },
      tempFilter: {
        lastversion: null,
        certificatecode: null,
        companies_id: null,
        contractsstandardsstatus_id: null,
        standards_id: null,
        certificationbodies_id: 133
      },
      filtersMenu: {
        companiesbg:[],
        contractsstandardsstatus: [],
        standardsbg:[],
        iafcodesbg: [],
      },
      fields: [{
        key: 'company',
        label: 'Company',
        sortable: true,
      },{
        key: 'standard',
        label: 'Standard',
        sortable: true,
      },{
        key: 'certificatecode',
        label: 'Code',
        sortable: true,
      },{
        key: 'issuedate',
        label: 'Issue',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'expiracydate',
        label: 'Expiry',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'decisiondate',
        label: 'Last decision',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'contractstandardstate',
        label: "Status",
        sortable: true,
      },{
        key: 'iafdate',
        label: 'IAF update',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'iafhash',
        label: 'IAF hash',
      },{
        key: 'checkdate',
        label: 'Check date',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'checksuccess',
        label: 'Check result',
        sortable: true
      }],
      items: {
        total: 0,
        data: [],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    computedData(){
      return this.items.data.map(function( item ) {
        let today = new Date();
        let expiry = new Date(item.expiracydate);
        item._cellVariants = {
          contractstandardstatus: item.isactive === null ? 'warning' : ( item.isactive === true ? 'success' : 'danger' ),
          expiracydate: today.getTime() >= expiry.getTime() && item.isactive === true ? 'danger' : '',
        };
        return item;
      });
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("contracts/certificates/", this.search).then((result) => {
        this.isBusy = false;
        this.items = result;
      })
    },
    filterOpen(){
      this.openHttp("contracts/certificates/filters", null).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        lastversion: null,
        certificatecode: null,
        companies_id: null,
        contractsstandardsstatus_id: null,
        standards_id: null,
        certificationbodies_id: 133
      }
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>