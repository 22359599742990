<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Experience" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Form" active>
            <b-row>
              <b-col lg="3">
                <b-form-group label="From">
                  <b-form-input v-model="data.experience.fromdate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="To (leave blank if ongoing)">
                  <b-form-input v-model="data.experience.todate" type="date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Company">
              <b-form-input v-model="data.experience.company"/>
            </b-form-group>
            <b-form-group label="Sector">
              <b-input-group v-for="item of data.experience.languages" :key="item.id" v-show="item.id === 2">
                <b-form-input v-model="item.sector"/>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Areas">
              <multiselect v-model="standardsareasSupport" :options="data.standardsareas" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.experience.standardsareas = updateCombo(standardsareasSupport)"/>
            </b-form-group>
            <b-form-group label="Responsibilities">
              <b-input-group v-for="item of data.experience.languages" :key="item.id" v-show="item.id === 2">
                <b-form-textarea v-model="item.responsibilities"/>
              </b-input-group>
            </b-form-group>
            <b-form-group label="IAFs">
              <multiselect v-model="nacesiafcodesSupport" :options="data.nacesiafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.experience.nacesiafcodes = updateCombo(nacesiafcodesSupport)"/>
            </b-form-group>
          </b-tab>
          <b-tab :title="'Documents (' + data.documents.length + ')'"  v-if="data.experience.id">
            <document ref="document" @update="loadData(data.experience.users_id, data.experience.id)"/>
            <b-btn variant="primary" @click="loadDocument(null)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive class="mt-3" :items="data.documents" :fields="fields" @row-clicked="loadDocument($event.file)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadFile(row.value)">Download</b-btn>
              </template>            
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.experience.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';

export default {
  name: "experience",
  components: { document },
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: 'filename',
        label: "Document",
      },{
        key: 'id',
        label: "",
      }]
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(usersId, id){
      this.openHttp("qualifications/curriculum/experience/", { users_id: usersId, id: id }).then((result) => {
        this.standardsareasSupport = this.convertCombo(result.standardsareas, result.experience.standardsareas);
        this.nacesiafcodesSupport = this.convertCombo(result.nacesiafcodes, result.experience.nacesiafcodes);
        this.filterNaces = null;
        this.data = result;
        this.show = true;
      })
    },
    loadDocument(fileName){
      this.$refs.document.loadData("experiences", this.data.experience.id, fileName);
    },
    saveData(){
      this.saveHttp("qualifications/curriculum/experience/save", this.data.experience).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("qualifications/curriculum/experience/delete", { id: this.data.experience.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    downloadAttachment(filename){
      this.$refs.document.downloadData("curriculumexperiences", this.data.experience.id, filename);
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>