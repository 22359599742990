<template>
  <div>
    <b-modal v-if="data != null" v-model="show" size="lg" title="Risk analysis" @hidden="closeForm()" @close="closeForm()">
      <b-alert show>
        <div><strong>Risk: </strong>{{ data.risk.risk }}</div>
        <div><strong>Causes: </strong>{{ data.risk.causes }}</div>
      </b-alert>
      <b-form-group label="Correction">
        <b-form-textarea v-model="data.analysis.mitigation" rows="3"/>
      </b-form-group>
      <b-form-group label="Corrective action">
        <b-form-textarea v-model="data.analysis.prevenction" rows="3"/>
      </b-form-group>
      <b-row>
        <b-col lg="3">
          <b-form-group label="Date">
            <b-form-input type="date" v-model="data.analysis.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Probability">
            <b-form-select v-model="data.analysis.probability" :options="data.probabilities" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Seriousness">
            <b-form-select v-model="data.analysis.seriousness" :options="data.seriousnesses" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Detectability">
            <b-form-select v-model="data.analysis.detectability" :options="data.detectabilities" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Evaluation">
            <b-form-select v-model="data.analysis.risksevaluations_id" :options="data.risksevaluations" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Residual risk">
        <b-form-textarea v-model="data.analysis.residualrisk" rows="3"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="warning" v-show="data.analysis.id > 0" class="mr-2" @click="data.analysis.id = 0">Duplicate</b-btn>
          <b-btn variant="danger" v-show="data.analysis.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "riskanalysis",
  data() {
    return {
      show: false,
      data: null
    }
  },
  methods: {
    loadData(id, risks_id){
      this.openHttp("certificationbody/riskanalysis/", { id: id, risks_id: risks_id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("certificationbody/riskanalysis/save", this.data.analysis).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("certificationbody/riskanalysis/delete", { id: this.data.analysis.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>
